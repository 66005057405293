import {Button, Form} from "antd";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import {isEmpty} from "../../../utils";

interface EditorProps {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onSubmit: () => void;
    submitting: boolean;
    value: string;
}

export const Editor = ({ onChange, onSubmit, submitting, value }: EditorProps) => (
    <Form layout={"vertical"}>
        <Form.Item label={"Написать сообщение"}>
            <TextArea rows={4} maxLength={5000} onChange={onChange} value={value} disabled={submitting} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" disabled={isEmpty(value)}
                    loading={submitting} onClick={onSubmit}
                    type="primary">
                Отправить
            </Button>
        </Form.Item>
    </Form>
);