import React from "react";
import {Button, Card, Table} from "antd";
import {Link, useLocation, useParams} from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";
import {DELETE_RESTRICTIONS, GET_RESTRICTIONS} from "./queries.graphql";
import PageMode from "../../../model/pageMode";
import DeleteButton from "../../../components/buttons/delete";
import {ColumnsType} from "antd/es/table";
import {NoRecordsText} from "../../../components/utils";
import {useAuth} from "../../../auth/authContext";

const RestrictsCard = ({userId}:any) => {
    const {isAdministrator, userInfo} = useAuth();
    const {id: specialistId} = useParams<{id: string, mode: PageMode}>()
    const location = useLocation();
    const {loading, data =  {restrictions: []}, refetch} = useQuery(GET_RESTRICTIONS, {
        variables: {
            specialistId: specialistId || userId
        }
    })

    const [deleteRestriction, {loading: deleting}] = useMutation(DELETE_RESTRICTIONS)

    const AddRestrictButton = () => !isAdministrator()
        ? null
        : (
            <Link to={`/user/profile/${specialistId}/restriction/`} state={{backgroundLocation: location}}>
                <Button type={"link"}>Добавить</Button>
            </Link>
        )

    const onDeleteConfirm = (id: any) => deleteRestriction({variables: {id}}).then(() => refetch())

    const cols:ColumnsType<any> = [
        { dataIndex: ["restrictionType","description"], key: "restrictionType"},
        { render: (record: any) => !isAdministrator() ? null : (<DeleteButton type={"icon"} onConfirm={() => onDeleteConfirm(record.id)} />)}
    ];

    console.log("userInfo", userInfo)
    return (
        <Card loading={loading || deleting} title={"Ограничения"} size={"small"} style={{width: "100%"}} extra={<AddRestrictButton/>}>
            {
                data.restrictions.length == 0
                    ? (<NoRecordsText/>)
                    : (<Table size={"small"} dataSource={data.restrictions} columns={cols} />)
            }
        </Card>
    )
}

export default RestrictsCard;