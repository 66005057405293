import {gql} from "@apollo/client";

export const GET_BATCHES_FOR_LOCATION = gql(`query batchesForLocation($locationId: ID) {
    batchesForLocation(locationId: $locationId) {
        id
        name
        description
        clients { id }
        isPrimary
    }
}`)

export const GET_BATCH = gql(`query batch($id: ID) {
    batch(id: $id) {
        id
        name
        location {id name}
        description
        clients { id name lastName surName }
    }
}`)

export const SAVE_BATCH = gql(`mutation saveBatch($props: BatchInput) {
    saveBatch(props: $props) {
        id
    }
}`)

export const DELETE_BATCH = gql(`mutation deleteBatch($batch: BatchInput) {
    deleteBatch(batch: $batch) {
        id
    }
}`)

