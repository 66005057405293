import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_CLIENTS} from "./query.graphql";
import {Col, Input, Row, Spin} from "antd";
import Client from "../../../model/client";
import {EditClientProps} from "../../client/edit";
import {ClientsList} from "./clientsList";

const EDIT_CLIENT_INITIAL_PROPS = {
    clientId: undefined,
    title: "Создание нового клиента",
}

export const ClientsListPage = () => {
    const [filter, setFilter] = useState("");
    const {data = {clients: []}, loading}
        = useQuery<{clients: Client[]}>(GET_CLIENTS, {pollInterval: 5000}) //TODO: Add error handling

    const [editClientProps, changeEditClientProps] = useState<EditClientProps>({
        ...EDIT_CLIENT_INITIAL_PROPS,
        onCancel: _ => changeEditClientProps({...editClientProps, visible: false, clientId: undefined})})

    const updateFilter = (e:React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value || "");

    const filteredData = (data.clients || [])
        .map(client => ({...client, fio: `${client.lastName} ${client.name} ${client.surName || ""}`}))
        .filter(({fio}) => fio.toLowerCase().includes(filter.toLowerCase()))

    return (<Spin spinning={loading}>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Input placeholder={"Введите фио для поиска"} style={{width: "100%"} } onInput={updateFilter} />
            </Col>
            <Col span={24}>
                <ClientsList dataSource={filteredData} />
            </Col>
        </Row>
    </Spin>)
}
