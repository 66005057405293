import React from "react";
import {ClientsList} from "../list/clientsList";
import {useQuery} from "@apollo/client";
import {GET_CLIENTS_BY_BATCH} from "./query.graphql";
import {ErrorResult} from "../../../components/result/error";

export interface ListByGroupsProps {
    batchId: string | number | undefined
}

export const ListByGroup = ({batchId}:ListByGroupsProps) => {
    const {data = {clientsForBatch: []}, loading, error} = useQuery(GET_CLIENTS_BY_BATCH,
        {
            pollInterval: 10000,
            variables: { id: batchId }
        })

    if (error) {
        return (
            <ErrorResult errors={error} />
        )
    }

    return (
        <ClientsList dataSource={[...data.clientsForBatch]} loading={loading} pagination={false} />
    )
}