import React, {useState} from "react";
import {Button, Col, Input, notification, Result, Row, Spin, Table, Tag} from "antd";
import {useQuery} from "@apollo/client";
import {GET_LOCATIONS_BATCHES} from "./query.graphql";
import {ListByGroup} from "./listByGroup";
import {ErrorResult} from "../../../components/result/error";
import {TableInterface} from "../TableInterface";

export const ClientsListByGroupsPage = () => {
    const {data:{locationsMainBatches} = {locationsMainBatches: []}, loading, error}
        = useQuery(GET_LOCATIONS_BATCHES, {pollInterval: 10000});

    const expandedRowRender = (record: any) => (<ListByGroup batchId={record.id}/>)

    if (error) {
        return (<ErrorResult errors={error} />)
    }

    const cols: TableInterface<any>[] = [
        {
            title: "Наименование группы",
            dataIndex: "name",
            filterSearch: true,
            filters: locationsMainBatches.map(({id, name}:any) => ({text: name, value: id })),
            onFilter: (value, record:any) => record.id == value,
        },
        {
            title: "Локация",
            dataIndex: ["location", "address"],
        },
    ]

    return (
        <Spin spinning={loading}>
            <Col span={24}>
                <Table rowKey={"id"}
                       columns={cols} dataSource={locationsMainBatches}
                       expandable={{
                           expandedRowRender
                       }}
                />
            </Col>
        </Spin>
    )
}
