import React from "react";
import {Card, Form, Modal, Select} from "antd";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_RESTRICTION_TYPES, SAVE_RESTRICTION} from "./queries.graphql";
import {useForm} from "antd/es/form/Form";

const EditRestrictionModal = ({onClose = () => {}}: any) => {
    const {userId} = useParams();
    const navigate = useNavigate();
    const [form] = useForm()
    const {loading, data = {restrictionTypes: []}} = useQuery(GET_RESTRICTION_TYPES)
    const [saveNewRestriction] = useMutation(SAVE_RESTRICTION)

    const onFinish = (restrictionType:string) => saveNewRestriction(
        {variables: {restriction: {target: {id: userId}, restrictionType}}}
    )
        .then(() => navigate(-1))
        .then(onClose);

    return (
        <Modal open={true} onCancel={() => navigate(-1)} okText={"Добавить"} closable={false} onOk={form.submit}>
            <Form form={form} onFinish={({restrictionType}) => onFinish(restrictionType)}>
                <Form.Item name={"restrictionType"} required label={"Ограничение"} rules={[
                    {required:true, message: "Выберите ограничение"}
                ]}>
                    <Select placeholder={"Выберите ограничение"}>
                        {
                            data.restrictionTypes.map(({name, description}: any) => (<Select.Option key={name}>{description}</Select.Option>))
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditRestrictionModal;