import {BatchesSelectorProps} from "./batchesSelectorProps";
import {useQuery} from "@apollo/client";
import Batch from "../../model/batch";
import {GET_BATHES} from "../../pages/client/edit/query.graphql";
import {isEmpty} from "../../utils";
import {Select} from "antd";
import {DEFAULT_PROPS} from "./utils";
import React from "react";

export const AllBatchSelector = (customProps: BatchesSelectorProps) => {
    const {data: {batches} = {batches: []}, loading} = useQuery<{ batches: Batch[] }>(GET_BATHES, {
        skip: !isEmpty(customProps?.locationId)
    })

    const batchesByLocation = reduceByLocation(batches)

    return (
        <Select {...DEFAULT_PROPS} {...customProps} loading={loading}>
            {
                Object.keys(batchesByLocation)
                    .sort((a: string) => a.includes("undefined") ? 1 : -1)
                    .map((key: string) => (
                        <Select.OptGroup label={getGroupLabelFromKey(key)}>
                            {
                                batchesByLocation[key].map(({id, name}: Batch) => (
                                    <Select.Option key={`batch_${id}`} value={id}>
                                        {name}
                                    </Select.Option>
                                ))
                            }
                        </Select.OptGroup>
                    ))
            }
        </Select>
    )
}

const getGroupLabelFromKey = (key: string) => {
    return key.includes("undefined") ? "Прочие" : key.split("_")[1];
}

const reduceByLocation = (batches: Batch[]) => {
    return batches.reduce((prev: any, curr: Batch) => {
        const {location} = curr;
        const key = `${location?.id}_${location?.name}`
        if (prev[key] !== undefined) {
            prev[key].push(curr)
        } else {
            prev[key] = [curr]
        }

        return prev
    }, {})
}