import {TableColumnsType, Typography} from "antd";
import Batch from "../../../model/batch";
import React from "react";

export const BATCH_LIST_COLUMNS:TableColumnsType<Batch> = [
    {
        title: "Наименование",
        width: "30%",
        dataIndex: "name"
    },
    {
        title: "Описание",
        dataIndex: "description",
        width: "20%",
        render: value => value || (<Typography.Text type={"secondary"}>Нет данных</Typography.Text>)
    },
    {
        title: "Кол-во участников",
        align: "center",
        render: (value, record) => record.clients?.length
    },
]