import "./wdyr";

import React from 'react';
import 'antd/dist/antd.css';
import BaseLayout from "./components/layout";
import {AuthProvider} from "./auth/authContext";
import locale from "antd/es/locale-provider/ru_RU";
import {ConfigProvider} from "antd";

function App() {
    return (
        <ConfigProvider locale={locale}>
            <AuthProvider>
                <BaseLayout/>
            </AuthProvider>
        </ConfigProvider>
    );
}

export default App;
