import React from 'react';
import {Select, Spin} from "antd";
import {SelectProps} from "antd/lib/select";
import {CabinetLocation} from "../../../model/cabinet";
import {useQuery} from "@apollo/client";
import {GET_LOCATIONS} from "../queies.graphql";

const DEFAULT_PROPS:SelectProps<any> = {
    placeholder: "Выберите локацию",
    showSearch: true,
    style: {width: "100%"},
    optionFilterProp: "children"
}

export const LocationSelector = (customProps:SelectProps<any>) => {
    const {loading, data={locations: []}} = useQuery<{locations: CabinetLocation[]}>(GET_LOCATIONS)
    //FIXME: При полнотекстовом поиске поиск происходит по value, а т.к. там ID, то по наименованиям он не ищет ;(

    return (
        <Spin spinning={loading}>
            <Select {...DEFAULT_PROPS} {...customProps}>
                {
                    data.locations
                        .map(({id, name}: CabinetLocation) => (<Select.Option value={`${id}`}>{name}</Select.Option>))
                }
            </Select>
        </Spin>
    )
}