import React from "react"
import {Button, Popconfirm, Popover} from "antd";
import {PopconfirmProps} from "antd/lib/popconfirm";
import {RenderFunction} from "antd/es/tooltip";
import {getChildren} from "./utils";

export type ChildType = "text" | "icon" | undefined

export interface DeleteButtonProps extends Omit<PopconfirmProps, "title">{
    title?: React.ReactNode | RenderFunction
    type? : ChildType
    popoverText?: string
}

const DEFAULT_PROPS:PopconfirmProps = {
    title: "Подтверждаете удаление?",
    okText: "Подтвердить",
    cancelText: "Отмена",
}

const DeleteButton = (customProps: DeleteButtonProps) => {
    const {type,disabled, popoverText} = {...DEFAULT_PROPS, ...customProps}
    const child = getChildren(type)
    return (
        <Popover content={popoverText || "Удалить"}>
            <Popconfirm {...DEFAULT_PROPS} {...customProps}>
                <Button disabled={disabled} type={"link"}>
                    {child}
                </Button>
            </Popconfirm>
        </Popover>
    )
}

export default DeleteButton