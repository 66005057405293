import {gql} from "@apollo/client";

export const GET_CLIENTS = gql(`query clients {
    clients {
        id
        lastName
        name
        surName
        birthday
        isDeleted
        batch { id name }
        validationStatus { name rusName }
        contactPerson { id lastName name surName phoneNumber }
    }
}`)

export const DELETE_CLIENT = gql(`mutation deleteClient($client:ClientInput){
    deleteClient(client: $client) {
        id
    }
}`);