import {useQuery} from "@apollo/client";
import {GET_ALL_VALIDATION_STATUSES} from "./queries.graphql";
import EntityValidationStatus from "../../model/validationStatus";
import {ColumnFilterItem} from "antd/es/table/interface";


export const useValidationStatusesFilter = function ():Array<ColumnFilterItem> {
    const {data = {entityValidationStatuses: []}} = useQuery<{entityValidationStatuses: Array<EntityValidationStatus>}>(GET_ALL_VALIDATION_STATUSES)

    return data.entityValidationStatuses.map(({name, rusName}) => ({
        text: rusName,
        value: name,
    }))
}
