import React, {useEffect} from "react";
import {Modal, notification} from "antd";
import {ModalProps} from "antd/es";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { useQuery} from "@apollo/client";
import {GET_BATCH} from "../queries.graphql";
import Batch from "../../../model/batch";
import {useForm} from "antd/es/form/Form";
import ModifyBatchForm from "./form";

const DEFAULT_PROPS: ModalProps = {
    open: true,
    okText: "Сохранить",
    width: "50%"
}

function constructProps(params: any, history: any): ModalProps {
    return {
        ...DEFAULT_PROPS,
        onCancel: () => history(-1),
        title: params.id === undefined ? "Создание новой группы" : "Редактирование группы"
    }
}

export const BatchDetailPage = (props: any) => {
    const params = useParams<any>();
    const [search] = useSearchParams();
    const [form] = useForm<any>()
    const navigate = useNavigate();
    const modalProps = constructProps(params, navigate)
    const {loading, data = {batch: {id: null, clients: []}}} = useQuery<{ batch: Batch }>(GET_BATCH, {
        skip: params.id === undefined,
        variables: {
            id: params.id
        }
    })

    const afterSave = () => {
        notification.success({message: "Сохранено"})
        navigate(-1)
    }

    useEffect(() => {
        form.setFieldsValue({
            clients: [],
            location: search.has("locationId") ? search.get("locationId") : null
        })
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            ...data.batch,
            clients: data?.batch?.clients,
            // @ts-ignore
            location: data?.batch.location?.id
        })
    }, [data, form])


    return (
        <Modal {...modalProps} onOk={form.submit}>
            <ModifyBatchForm mode={"FULL"} form={form} onAfterFinish={afterSave}
                             locationId={search.get("locationId")}

            />
        </Modal>
    )
}