import React from "react";
import {Card, Col, Descriptions, Row, Tag,} from "antd";
import {NoDataText} from "../../../components/utils";
import {useQuery} from "@apollo/client";
import {GET_PERSON_INFO} from "./queries.graphql";
import {getCardExtra} from "./profile";
import {getRolesRusNames, INITIAL_USER_INFO} from "../utils";
import {useAuth} from "../../../auth/authContext";
import {DefaultAvatar} from "../avatar";
import {RoleCode} from "../../../model/roles";

export const PersonalInfoView = (props: { id: string }) => {
    //TODO: Прикрутить обработку ошибок
    const {userInfo} = useAuth()
    const {data = {person: INITIAL_USER_INFO}, loading, error} = useQuery(GET_PERSON_INFO, {
        skip: props.id === "" || props.id === null || props.id === undefined,
        variables: {id: props.id}
    })
    const { lastName, name, surName, description, phoneNumber, accountInfo, personType } = data.person;
    const authBadge = !accountInfo?.canAuth ? <Tag color="error">Заблокирован</Tag> : null;
    const {roles} = accountInfo || {roles: []}

    return (
        <Card loading={loading} title={"Персональная информация"} extra={getCardExtra(props.id, userInfo.roles)}>
            <Row>
                <Col span={6} style={{textAlign: "center"}}>
                    <DefaultAvatar src={accountInfo?.iconUrl || "/img/profile_img.png"} />
                    {authBadge}
                </Col>
                <Col span={12}>
                    <Descriptions column={1} layout={"horizontal"}>
                        <Descriptions.Item label="ФИО">{lastName} {name} {surName}</Descriptions.Item>
                        <Descriptions.Item label="Номер телефона">{phoneNumber}</Descriptions.Item>
                        <Descriptions.Item label="Роль">
                            <RolesCards roles={[getPersonTypeRusName(personType),getRolesRusNames(roles)]}/>
                        </Descriptions.Item>
                        <Descriptions.Item span={3} label="Описание">{description || (<NoDataText/>)}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </Card>
    )
}

const getPersonTypeRusName = (code: string) => code === "EMPLOYEE" ? "Сотрудник" : "Клиент"
const RolesCards = ({roles}: {roles: string[]}) => {

    const tags = roles.map(role => (<Tag className={"role"}>{role}</Tag>))
    return (<>{tags}</>)
}