import React from "react";
import {Form, FormInstance, Select} from "antd";
import {REQUIRED_RULE} from "../../user/utils";
import DefaultDatePicker from "../../../components/datepicker";
import {FavorSelector} from "../../../components/favors";
import {LocationSelector} from "../../../components/locations/selector";
import moment, {Moment} from "moment/moment";
import {CreationTimesheetMode} from "./index";
import {useAuth} from "../../../auth/authContext";
import SpecialistSelector from "../../../components/persons/specialists/selector";

const CreateTimesheetForm = ({form, mode}: {form: FormInstance, mode: CreationTimesheetMode}) => {
    const {isAdministrator} = useAuth()

    const isDisabledDate = (date:Moment) => {
        if (isAdministrator()) return false;

        const now = moment().startOf("day");
        const _date = date.startOf("day")
        const isInFiveDaysBefore = Math.abs(_date.diff(now, "day")) === 5 //FIXME: TABEL-29

        return !isInFiveDaysBefore && (_date.isBefore(now) || now.year() < date.year());
    }

    return (
        <Form form={form} layout={"vertical"}>
            <Form.Item label={"Выберите месяц"} name={"date"} rules={[REQUIRED_RULE]}
            >
                <DefaultDatePicker picker={"month"} format={"MMMM YYYY"} placeholder={"Выберите месяц"}
                                   disabledDate={isDisabledDate} />
            </Form.Item>
            {
                mode === "clone"
                    ? null
                    : (
                        <>
                            <Form.Item label={"Услуга"} name={"favor"} rules={[REQUIRED_RULE]}>
                                <FavorSelector/>
                            </Form.Item>
                            <Form.Item label={"Место проведения занятий"} name={"location"} rules={[REQUIRED_RULE]}>
                                <LocationSelector/>
                            </Form.Item>
                            <SpecialistSelectorFormItem />
                        </>
                    )
            }
        </Form>
    )
}

const SpecialistSelectorFormItem = () => {
    const {isAdministrator} = useAuth()

    if (!isAdministrator()) return null;

    return (
        <Form.Item label={"Специалист"} name={"specialist"} rules={[REQUIRED_RULE]}>
            <SpecialistSelector/>
        </Form.Item>
    )
}

export default CreateTimesheetForm