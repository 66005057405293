import {gql} from "@apollo/client";


export const GET_FAVORS = gql(`query favors {
    favors {
        id
        name
        price
        description
    }
}`)

export const GET_FAVORS_SELECTOR = gql(`query favors {
    favors {
        id
        name
    }
}`)
