import {RoleCode, RoleRusName} from "../../model/roles";
import {Person} from "../../model/person";
import {Rule} from "rc-field-form/lib/interface";

export const INITIAL_USER_INFO:Person = {
    id: "", name: "",  lastName: "", surName: "", birthday: null, phoneNumber: "",
    accountInfo: {id: "", iconUrl: "", login: "", roles: [], canAuth: true},
    description: "",
}

export const getRolesRusNames = (roles: Array<RoleCode>) => {
    return roles?.map(mapRoleName)?.join(", ");
}

export const mapRoleName = (code: RoleCode):string => {
    return RoleRusName[code];
}
export const RUSSIAN_PATTERN = /^[ аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ-]+$/
export const PHONE_NUMBER_REGEX = /^\+7 \d{3} \d{3} \d{4}$/

export const REQUIRED_RULE:Rule = {required: true, message: "Обязательно для заполнения"}
export const PRONE_NUMBER_RULE:Rule = {
    pattern: PHONE_NUMBER_REGEX, message: "Введите корректный номер",
}
export const RUSSIAN_PATTERN_RULE:Rule = {
    pattern: RUSSIAN_PATTERN,
    message: "Введены недопустимые символы. Допустимы только русские буквы"
}