import React, {useContext, useEffect, useState} from 'react';
import moment, {Moment} from "moment";
import Favor from "../../model/favor";
import Specialist from "../../model/specialist";
import {CabinetLocation} from "../../model/cabinet";
import {TimesheetStatus} from "../../model/timesheetStatus";
import Timesheet from "../../model/timesheet";
import {TIMESHEET_INITIAL} from "./utils";
import {useQuery} from "@apollo/client";
import {GET_TIMESHEET_LIMITS} from "./limits/query.graphql";
import {isEmpty} from "../../utils";

type TimesheetContextData = ReturnType<typeof useProvideTimesheet>;

const TimesheetContext = React.createContext<TimesheetContextData>({} as TimesheetContextData);

export const useTimesheetContext = () => {
    return useContext(TimesheetContext);
};

export const TimesheetProvider = (props:any) => {
    const { children } = props;
    const timesheet = useProvideTimesheet(props.value);
    return (
        <TimesheetContext.Provider value={timesheet}>
            {children}
        </TimesheetContext.Provider>
    );
};

const NOTHING_TO_DO = (val: any) => {}

const useProvideTimesheet = (defaultValue: any) => {
    const [timesheet, setTimesheet] = useState<Timesheet>(TIMESHEET_INITIAL)
    const [loading, setLoading] = useState<boolean>(false)
    const [limits, setLimits] = useState<any[]>([])
    const [errors, setErrors] = useState<string[]>([])
    const [warnings, setWarnings] = useState<string[]>([])
    const [date, setDate] = useState<Moment>(moment())
    const [favor, setFavor] = useState<Favor>()
    const [specialist, setSpecialist] = useState<Specialist>()
    const [location, setLocation] = useState<CabinetLocation>()
    const [addDateCallback, setAddDateCallback] = useState<(val:any) => void>(NOTHING_TO_DO)
    const [status, setStatus] = useState<string>(TimesheetStatus.NEW)

    const {refetch:updateLimits, data:{timesheetLimits} = {timesheetLimits: []}, loading: loadingLimits} = useQuery(GET_TIMESHEET_LIMITS,
        {
            variables: {timesheetId: timesheet?.id},
            skip: isEmpty(timesheet?.id)
        })

    useEffect(() => {
        if (defaultValue != undefined) {
            setTimesheet(defaultValue.timesheet);
            setLoading(defaultValue.loading)
            setDate(moment(defaultValue.date, "YYYY-MM-DD"))
            setFavor(defaultValue.favor)
            setSpecialist(defaultValue.specialist)
            setLocation(defaultValue.location)
            setAddDateCallback(defaultValue.onAddDate)
            setErrors(defaultValue.errors)
            setWarnings(defaultValue.warnings)
            setStatus(defaultValue.status)
        }
    }, [defaultValue])

    return {
        timesheet, loading: loading || loadingLimits,
        limits, setLimits, updateLimits,
        date, setDate,
        favor, setFavor,
        status, setStatus,
        specialist, setSpecialist,
        location, setLocation,
        errors, setErrors, addError: (message: string) => { setErrors([...errors, message]) },
        warnings, setWarnings, addWarning: (message: string) => { setWarnings([...warnings, message]) },
        onAddDate: addDateCallback, setAddDateCallback
    };
};

export default TimesheetContext