import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from "moment";
import {Spin} from "antd";
import {useQuery} from "@apollo/client";
import { GET_WORK_PERIODS} from "./graphql.query";
import {useAuth} from "../../auth/authContext";

moment.locale("ru");
const localizer = momentLocalizer(moment);

const TimeTable = ({history, location}:any) => {
    const {loading, data = {workPeriods: []}} = useQuery<{workPeriods: any[]}>(GET_WORK_PERIODS);
    const {userInfo} = useAuth()

    const events = data.workPeriods.map((item:any, index:number)=> ({
        id: index,
        title: `${item.id} ${item.location.name}`,
        start: moment(`${item.date} ${item.startTime}`).toDate(),
        end: moment(`${item.date} ${item.endTime}`).toDate(),
        onDoubleClick: () => {
            history(item?.timesheet?.id
                ? `/tabel/${item?.timesheet?.id}`
                : "/tabels/create",
                {backgroundLocation: location, workPeriod: item.id}
            )
        },
        color: "#1890ff",
    }))

    return (
        <Spin spinning={loading}>
            <Calendar localizer={localizer}
                      events={events}
                      min={getTime(6, 0)}
                      max={getTime(22, 0)}
                      selectable
                      onDoubleClickEvent={(event) => event?.onDoubleClick()}
                      defaultView={Views.WEEK}
                      views={[
                          Views.WEEK
                      ]}
            />
        </Spin>
    )
}

function getTime(hours:number, minutes:number) {
    const date = new Date();
    date.setHours(hours, minutes, 0);
    return date;
}
export default TimeTable;