import React from "react";
import {Button, Card, Col, Row, Space} from "antd";
import {Link, useParams} from "react-router-dom";
import {PersonalInfoView} from "./view";
import {EditUserInfoView} from "./edit";
import {Role, RoleCode} from "../../../model/roles";
import TimesheetsPage from "../../timesheets";
import {useAuth} from "../../../auth/authContext";
import PageMode from "../../../model/pageMode";
import {useQuery} from "@apollo/client";
import {GET_PERSON_INFO} from "./queries.graphql";
import {Person} from "../../../model/person";
import RestrictsCard from "./restricts";

export const UserProfile = (props: any) => {
    const {id, mode} = useParams<{id: string, mode: PageMode}>()
    const {userInfo} = useAuth();
    const userId = id || userInfo.id
    // @ts-ignore
    const isEditMode = [PageMode.CREATE, PageMode.EDIT].includes(mode);

    return (
        <Col span={24}>
            <Row gutter={[16,16]}>
                <Col span={isEditMode ? 24 : id === undefined ? 24 : 8}>
                    <Row gutter={16}>
                        {
                            isEditMode
                                ? <EditUserInfoView {...{id: id === "new" ? undefined : userId}} />
                                : <PersonalInfoView {...{id: userId}}/>
                        }
                    </Row>
                    <br/>
                    <Row gutter={[16,16]}>
                        <RestrictsCard userId={userId} />
                    </Row>
                </Col>
                <Col>
                    <Row>
                        {
                            isEditMode ? null :
                                (id === undefined || userInfo?.id === undefined) ? null
                                    : (<TimesheetsBlock userId={id || userInfo.id}/> )
                        }
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

export const getCardExtra = (id: string | number, roles: Role[]) => {
    if (roles?.map(role => role.code)?.includes(RoleCode.ADMINISTRATOR)) {
        return (
            <Button type={"link"}>
                <Link to={`/user/profile/${id}/edit`}>Редактировать</Link>
            </Button>
        )
    }

    return null;
}

const TimesheetsBlock = ({userId}: {userId: string}) => {
    const {loading, data = {person: {accountInfo: { roles: []}}}} = useQuery<{person: Person}>(GET_PERSON_INFO, {
        variables: {id: userId}
    })

    // @ts-ignore
    const isSpecialist = data.person?.accountInfo?.roles.includes("SPECIALIST") || false

    return !isSpecialist ? null : (
        <Card title={"Табеля"} loading={loading}>
            <TimesheetsPage showTitle={false} id={userId} />
        </Card>
    )

}

