import React, {useState} from "react";
import {Button, Col, Divider, Input, Row, Spin, Table, Tag, Typography} from "antd";
import {useQuery} from "@apollo/client";
import {GET_EMPLOYEES} from "./queries.graphql";
import Specialist from "../../../../model/specialist";
import {ColumnsType} from "antd/es/table";
import { Link } from "react-router-dom";
import {RoleCode, RoleRusName} from "../../../../model/roles";
import {NoDataText} from "../../../../components/utils";

interface GetSpecialistResponseProps {
    employees: Specialist[]
}

export const AdminSpecialistsPage = ({history}:any) => {
    const {loading, data = {employees: []}} = useQuery<GetSpecialistResponseProps>(GET_EMPLOYEES)
    const [filter, setFilter] = useState("");
    const cols:ColumnsType<Specialist> = [
        {
            title: "ФИО",
            render: (value: any, {name, lastName, surName}:any) => (<span>{lastName} {name} {surName || ""}</span>)
        },
        {
            title: "Роли",
            render: (value: any, record: Specialist) => record?.accountInfo !== null
                ? record?.accountInfo?.roles?.map(role => RoleRusName[role]).map(item => (<Tag>{item}</Tag>))
                : (<NoDataText/>)
        },
        {
            title: "Действия",
            width: 300, align: "center",
            render: (_:any, rec: any) => ([
                (<Link to={{pathname: `/admin/specialist/${rec.id}/tabels`}}>
                    <Button disabled={!rec.accountInfo?.roles?.includes(RoleCode.SPECIALIST)} type={"link"}>Табеля</Button>
                </Link>),
                (<Divider type={"vertical"}/>),
                (<Link to={{pathname: `/user/profile/${rec.id}`}}><Button type={"link"}>Профиль</Button></Link>),
            ])
        }
    ]

    const updateFilter = (e:React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value || "");

    const filteredData = (data.employees || [])
        .map(client => ({...client, fio: `${client.lastName} ${client.name} ${client.surName || ""}`}))
        .filter(({fio}) => fio.toLowerCase().includes(filter.toLowerCase()))

    return (
        <Spin spinning={loading}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Input placeholder={"Введите фио для поиска"} style={{width: "100%"} } onInput={updateFilter} />
                </Col>
                <Col span={24}>
                    <Table dataSource={filteredData} columns={cols}
                           footer={() => ([
                               (<Link to={{pathname: `/user/profile/new/create`}}><Button type={"link"}>Добавить</Button></Link>)
                           ])}
                    />
                </Col>
            </Row>
        </Spin>
    )
}