import React from "react";
import {Button, Divider, notification, Table, Tag} from "antd";
import Client from "../../../model/client";
import moment from "moment";
import {isEmpty} from "../../../utils";
import {NoDataText} from "../../../components/utils";
import EntityValidationStatusTag from "../../../components/entityValidationStatus/tag";
import {useValidationStatusesFilter} from "../../../components/entityValidationStatus/tableFilter";
import {useLocation, useNavigate} from "react-router-dom";
import {TableInterface} from "../TableInterface";
import DeleteButton from "../../../components/buttons/delete";
import {useMutation} from "@apollo/client";
import {DELETE_CLIENT} from "./query.graphql";


export interface ClientsListProps {
    dataSource: any
    loading?: boolean
    pagination?: false | undefined
}
export const ClientsList = ({dataSource, loading, pagination}:ClientsListProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [fetchDeleting, {loading: deleting}] = useMutation(DELETE_CLIENT)

    const deleteClient = (client: Client) => {
        const options = { variables: { client: { id: client.id } } }
        fetchDeleting(options)
            .then(() => notification.success({message: "Сохранено"}))

    }

    const cols:TableInterface<any>[] = [
        {
            title: "ФИО",
            dataIndex: "",
            render: (_:any, record:Client) => (<span>
                {record.lastName} {record.name} {record.surName || ""}
                {!record.isDeleted ? null : <Tag color={"error"}>Удален</Tag>}
            </span>)
        },
        {
            title: "Дата рождения",
            dataIndex: "birthday",
            render: ((birthday:string) => birthday === null ? "Нет данных" : moment(birthday).format("DD.MM.YYYY"))
        },
        {
            title: "Группы",
            dataIndex: "batch",
            filterMode: "tree",
            filterSearch: true,
            render: (_:any, record:Client) => _.map((it:any) => <Tag>{it.name}</Tag>)
        },
        {
            title: "Контактное лицо",
            children: [
                {
                    title: "ФИО",
                    dataIndex: "contactPerson",
                    render: (_:any, record:Client) => {
                        if (isEmpty(_)) return <NoDataText/>
                        return `${_.lastName} ${_.name} ${_.surName || ""}`
                    }
                },
                {
                    title: "Номер телефона",
                    dataIndex: "contactPerson",
                    render: (_:any, record:Client) => {
                        if (isEmpty(_?.phoneNumber)) return <NoDataText/>
                        return `${_?.phoneNumber}`
                    }
                }
            ]

        },
        {
            title: "Статус валидации",
            dataIndex: ["validationStatus"],
            render: (status, rec) => (<EntityValidationStatusTag status={status} />),
            filters: useValidationStatusesFilter(),
            onFilter: (value, record) => record?.validationStatus?.name?.indexOf(value) === 0
        },
        {
            title: "Действия",
            align: "center", width: 350,
            render: (_:any, rec: Client) => [
                <Button type={"link"} onClick={() => editClient(rec.id)}>Редактировать</Button>,
                <Divider type={"vertical"}/>,
                <DeleteButton type={"icon"} onConfirm={() => deleteClient(rec)} />
            ]
        }
    ]

    function editClient(id?: string | number) {
        navigate(
            `/clients/${isEmpty(id) ? "create" : "edit"}${isEmpty(id) ? "" : `/${id}`}`,
            {state: {backgroundLocation: location}}
        )
    }

    return (
        <Table loading={loading || deleting || false} size={"small"}
               rowKey={"id"}
               dataSource={dataSource} columns={cols}
               pagination={pagination}
               footer={() => [
            <Button type={"link"} onClick={() => editClient("")}>Добавить</Button>
        ]}/>
    )
}