import {gql} from "@apollo/client";

export const CLONE_TIMESHEET_MUTATION = gql(`mutation cloneTimesheet($props:TimesheetInput, $source: ID){
    cloneTimesheet(props: $props, source: $source) {
        id
        specialist { id lastName name }
        lessons { id status date }
        defaultFavor {id name}
        clients {id lastName name lessons {id}}
    }
}`);


export const SAVE_TIMESHEET_MUTATION = gql(`mutation saveTimesheet($props:TimesheetInput){
    saveTimesheet(props: $props) {
        id
    }
}`);