enum PageMode {
    EDIT="edit",
    CREATE="create",
    VIEW="view",
    DELETE="delete"
}

export enum PageModeRusName {
    edit="Редактирование",
    create="Создание",
    view="Просмотр",
    delete="Удаление"
}

export default PageMode