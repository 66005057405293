import {gql} from "@apollo/client";

export const GET_PERSON_DETAILS = gql(`query person($id: ID) {
    person(id: $id) {
        id
        name
        lastName
        surName
        birthday
        phoneNumber
        description
        accountInfo { id login canAuth password iconUrl roles }
    }
}`)