import React from "react";
import {Link} from "react-router-dom";
import {Button} from "antd";
import {TimesheetStatus} from "../../../../../model/timesheetStatus";

const GoToButton = ({timesheetId, status}: {timesheetId: string, status: TimesheetStatus}) => {
    return (
        <Link to={`/tabel/${timesheetId}`}>
            <Button disabled={isDisabled(status)} type={"link"}>Перейти</Button>
        </Link>
    )
}

const isDisabled = (status:TimesheetStatus) => {
    return ![TimesheetStatus.NEW, TimesheetStatus.ACTIVE].includes(status)
}

export default GoToButton