import React, {useContext} from "react";
import {Modal, notification, Spin} from "antd";
import {ModalProps} from "antd/es";
import {useForm} from "antd/es/form/Form";
import {FetchResult, useMutation} from "@apollo/client";
import Timesheet from "../../../model/timesheet";
import AuthContext, {useAuth} from "../../../auth/authContext";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import {TimesheetStatus} from "../../../model/timesheetStatus";
import CreateTimesheetForm from "./form";
import {CLONE_TIMESHEET_MUTATION, SAVE_TIMESHEET_MUTATION} from "./query.graphql";

const DEFAULT_MODAL_PROPS:ModalProps = {
    visible: true,
    okText: "Создать",
    cancelText: "Отмена"
}

enum RusModeTitle {
    create="Создание",
    clone="Копирование"
}

export type CreationTimesheetMode = "create" | "clone"
const TimeSheetPropsModal = () => {
    const [form] = useForm();
    const {userInfo, isAdministrator} = useAuth()
    const {source, mode} = useParams<{mode: CreationTimesheetMode, source: string}>()
    const navigate = useNavigate();
    const authContext = useContext(AuthContext)
    const [saveTimeSheetMutation, {loading:saving}] = useMutation(SAVE_TIMESHEET_MUTATION)
    const [cloneTimeSheetMutation, {loading:cloning}] = useMutation(CLONE_TIMESHEET_MUTATION)

    const addTimesheet = () => {
        const options:{variables: { props: any, source?: any}} = {
            variables: {
                props: {
                    specialist: {id: form.getFieldValue("specialist") || authContext.userInfo.id},
                    date: moment(form.getFieldValue("date")).format("YYYY-MM-01"),
                    defaultFavor: {id: form.getFieldValue("favor")},
                    location: {id: form.getFieldValue("location")},
                    status: TimesheetStatus.NEW
                }
            }
        }

        if (mode === "clone") {
            options.variables = {
                ...options.variables,
                source
            }
        }

        getCreatingFunc()(options)
            .then((resp: FetchResult<{saveTimesheet?: Timesheet, cloneTimesheet?: Timesheet}>) => {
            notification.success({message: "Сохранено"});
            if (isAdministrator()) {
                navigate(-1)
            } else {
                navigate(`/tabel/${mode === "create" ? resp.data?.saveTimesheet?.id : resp.data?.cloneTimesheet?.id }`)
            }
        }).catch((reason) => {
            notification
                .error({message: "При сохранении возникли ошибки", description: reason.message, duration: 15})
        })
    }

    const getCreatingFunc = () => {
        return mode === "clone" ? cloneTimeSheetMutation : saveTimeSheetMutation
    }

    if (mode == "clone") {
        DEFAULT_MODAL_PROPS.title = "Копирование табеля"
    }

    return (
        <Spin spinning={saving || cloning}>
            <Modal {...DEFAULT_MODAL_PROPS} title={`${RusModeTitle[mode || "create"]} табеля`}
                   onOk={() => form.validateFields().then(addTimesheet)}
                   onCancel={() => navigate(-1)}
            >
                <CreateTimesheetForm form={form} mode={mode || "create"} />
            </Modal>
        </Spin>
    )
}
export default TimeSheetPropsModal