import {gql} from "@apollo/client";

export const GET_LESSONS = gql(`query {
    lessons {
        id
        date
        time
    }
}`)

export const GET_WORK_PERIODS =gql(`query workPeriods {
    workPeriods {
        id
        date
        startTime
        endTime
        specialist { id lastName name surName}
        location {id name}
        timesheet { id }
        batches {id name}
    }
}`)