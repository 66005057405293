import React, {useEffect, useState} from "react"
import {Button, Col, Row, Spin, Table, TablePaginationConfig, Tag} from "antd";
import Title from "antd/es/typography/Title";
import {useQuery} from "@apollo/client";
import {GET_TIMESHEETS_WIT_FILTERS} from "./query.graphql";
import {Link, useLocation, useParams} from "react-router-dom";
import Timesheet from "../../model/timesheet";
import moment from "moment";
import {ColumnsType} from "antd/es/table";
import {NoDataText} from "../../components/utils";
import {TimesheetStatus, TimesheetStatusRusName} from "../../model/timesheetStatus";
import {downloadTimesheetReport} from "./download";
import DownloadButton from "../../components/buttons/download";
import CloneButton from "./components/buttons/clone";
import GoToButton from "./components/buttons/goto";

export interface TimesheetPageProps {
    showTitle?: boolean
    id?: string
    title?: string
}

const getTimesheetRusName = (code: string) => {
    const rusName:TimesheetStatusRusName = TimesheetStatusRusName[code as keyof typeof TimesheetStatus]

    return (<Tag key={code}>{rusName}</Tag>)
}

const TimesheetsPage = (props: TimesheetPageProps) => {
    const location = useLocation();
    const params = useParams<{id: string}>()
    const [totalLoading, setTotalLoading] = useState(false);
    var [pageState, setPageState] = useState({totalPages: 1, currentPage: 0, pageSize: 5, totalElements: 5});

    const vars = {
        filters: {specialists: [params.id || props.id]},
        pageNumber: 0,
        pageSize: 10
    }

    const {loading, data = {
        timesheetsWithFilter: {data:[], pageInfo: {pageSize: 10, totalPages: 1, totalElements: 10}}}, error, refetch} = useQuery(
        GET_TIMESHEETS_WIT_FILTERS, {
            variables: vars
        }
    )

    useEffect(() => {
        setPageState({
            pageSize: 10,
            totalPages: data.timesheetsWithFilter.pageInfo.totalPages,
            currentPage: data.timesheetsWithFilter.pageInfo.number,
            totalElements: data.timesheetsWithFilter.pageInfo.totalElements
        })
    }, [data.timesheetsWithFilter.pageInfo]);

    const onPageChange = (page: number) => {
        setTotalLoading(true)

        refetch({
            ...vars,
            pageNumber: page - 1
        }).then(({data: {timesheets: {pageInfo}}}) => setPageState({totalElements: data.timesheetsWithFilter.pageInfo.totalElements, pageSize: 10, totalPages: Number.parseInt(pageInfo.endCursor), currentPage: page -1 }))
            .finally(() => setTotalLoading(false))
    }

    const columns:ColumnsType<any> = [
        {
            title: 'Период',
            dataIndex: 'date',
            render: (date: string, rec: Timesheet) => moment(date).format("MMMM YYYY"),
            key: 'date',
        },
        {
            key: "favor",
            title: "Услуга",
            dataIndex: ["defaultFavor", "name"],
            render: (favorName: string, rec: Timesheet) => favorName === null ? "Нет данных" : favorName
        },
        {
            key: "location",
            title: "Место проведения занятий",
            dataIndex: ["location", "name"],
            render: (name: string, rec: Timesheet) => name === null ? (<NoDataText/>) : name
        },
        {
            key: "lessonsAmont",
            title: "Кол-во занятий",
            dataIndex: "completedLessonsAmount",
            align: "center",
        },
        {
            key: "status",
            title: "Статус",
            dataIndex: "status",
            align: "center",
            render: getTimesheetRusName
        },
        {
            key: "actions",
            title: "Действия",
            align: "center",
            render: (_: any, record: any) => ([
                <GoToButton key={`goto_${record.id}`} timesheetId={record.id} status={record.status} />,
                <DownloadButton key={"downloadBtn"} disabled={record.completedLessonsAmount === 0} onClick={() => downloadTimesheetReport(record.id, "BASIC")} />,
                <CloneButton key={"closeBtn"} timesheetId={record.id} />
            ])
        }
    ]

    const dataSource:any[] =  ((data.timesheetsWithFilter.data || data.allTimesheets.data) || []);

    const pagination:TablePaginationConfig = {
        onChange: onPageChange,
        pageSize: pageState.pageSize,
        current: pageState.currentPage+1,
        defaultCurrent: 1,
        total: pageState.totalElements,
        position: ["bottomRight"],
        showSizeChanger: false
    }

    return (
        <Row gutter={16}>
            <Col span={24}>
                {
                    (props.showTitle !== undefined ? props.showTitle : true) ? (<Row><Title level={2}>Табеля</Title></Row>) : null
                }
                <Row gutter={16}>
                        <Col span={24} style={{display: "block"}}>
                            <Spin spinning={loading}>
                                <Table columns={columns} dataSource={dataSource}
                                       rowKey={"id"} loading={loading || totalLoading}
                                       pagination={pagination}
                                       footer={() => (<Link to={`/tabels/create`} state={{backgroundLocation: location}}>
                                           <Button type={"link"} >Добавить</Button>
                                       </Link>)}/>
                            </Spin>
                        </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default TimesheetsPage