import {Person} from "./person";
import Timesheet from "./timesheet";

export interface Message {
    id: string | undefined
    message: string
    sender: Person
    timesheet: Timesheet
    status: MessageStatus
    auditedInfo: {
        createdBy: string
        modifiedDate: string
        createdDate: string
        modifiedBy: string
    }
}

export enum MessageStatus {
    "SENT" ="SENT",
    "READ" ="READ",
}