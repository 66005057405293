import {Link, useLocation} from "react-router-dom";
import {Button, Popover} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import React from "react";

const CloneButton = ({timesheetId}: {timesheetId: string}) => {
    const location = useLocation();

    return (
        <Popover content={"Копировать табель"}>
            <Link to={`/tabels/clone/${timesheetId}`} state={{backgroundLocation: location}}>
                <Button type={"link"}>
                    <CopyOutlined/>
                </Button>
            </Link>
        </Popover>
    )
};

export default CloneButton;