import React from "react";
import {Col, Empty, Result, Row, Spin} from 'antd';
import {useTimesheetContext} from "../context";
import {useQuery} from "@apollo/client";
import {GET_TIMESHEET_MESSAGES} from "./query.graphql";
import {Comment} from "./comment";
import {MessageInput} from "./messageInput";

export const CorrespondenceTab = () => {
    const context = useTimesheetContext();

    const {refetch, data:{findAllByTimesheet:messages} = {findAllByTimesheet : []}, loading} = useQuery(GET_TIMESHEET_MESSAGES, {
        variables: {
            timesheetId: context.timesheet.id
        },
        pollInterval: 10000,
        skip: true
    })

    return (
        <Spin spinning={loading}>
            <Row>
                <Col span={12}>
                    {
                        messages.length > 0
                            ? messages.map((comment:any) => (<Comment onEdit={refetch} comment={comment}/>))
                            : (<NoMessages />)
                    }
                </Col>
                <Col span={12}>
                    <MessageInput refetch={refetch} />
                </Col>
            </Row>
        </Spin>
    )
}

const NoMessages = () => (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Сообщений нет"} />)