import React from "react";
import {UserOutlined} from "@ant-design/icons";
import {Avatar} from "antd";
import {AvatarSize} from "antd/es/avatar/SizeContext";
import {AvatarProps} from "antd/lib/avatar/avatar";

const DEFAULT_PROPS:AvatarProps = {
    style: {marginBottom: 10},
    alt: "Фотография пользователя",
    size: { xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 },
    src: "/img/profile_img.png",
    icon: (<UserOutlined />)
}

export const DefaultAvatar = (customProps: AvatarProps) => {
    return (
        <Avatar {...DEFAULT_PROPS} {...customProps}/>
    )
}