import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {ChildType} from "./index";

export const getChildren = (type: ChildType):React.ReactNode => {
    if (!type || type === "text") return Children.TEXT;
    if (type === "icon") return Children.ICON;
    else return Children.TEXT;

}

export class Children {
    static TEXT = "Удалить"
    static ICON = <DeleteOutlined />
}
