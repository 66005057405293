import {gql} from "@apollo/client";

export const GET_TIMESHEET = gql(`query($id: ID) {
    timesheet(id: $id) {
        id
        date
        clients {id fio batch {id name}}
        lessons {id date status specialist {id} client {id} favor {id name} timesheet {id}}
        defaultFavor { id name }
        specialist { id name lastName surName }
        status
        location { id name }
        notes {id client {id} note}
    }
}`)

export const SAVE_TIMESHEET_MUTATION = gql(`mutation saveTimesheet($props:TimesheetInput){
    saveTimesheet(props: $props) {
        id
        specialist { id lastName name }
        lessons { id status date }
        defaultFavor {id name}
        clients {id lastName name lessons {id}}
    }
}`);

export const DELETE_LESSONS_MUTATION = gql(`mutation deleteLessons($lessons: [LessonInput]) {
    deleteLessons(lessons: $lessons) { id }
}`)