import {gql} from "@apollo/client";

const GET_CLIENTS_AND_GROUPS_FOR_TIMESHEET = gql(`query clientsForTimesheet($timesheet: ID) {
    clientsForTimesheet(timesheet: $timesheet) {
        id
        fio
        meta
        batch { id name }
    }
    
    batchesForTimesheet(timesheet: $timesheet) {
        id
        name
        description
        clients { id fio meta }
    }
}`)

const GET_CLIENTS_AND_GROUPS = gql(`query {
    clients {
        id
        name
        lastName
        surName
    }
    
    batches {
        id
        name
        description
        clients { id }
    }
}`)

const GET_BATCH = gql(`query batch($id: ID) {
    batch(id: $id) {
        id
        clients {id fio }
    }
}`)

export {GET_CLIENTS_AND_GROUPS, GET_BATCH, GET_CLIENTS_AND_GROUPS_FOR_TIMESHEET}
