import React, {useState} from "react";
import {Button, Card, Col, DatePicker, Form, Input, message, Popconfirm, Row, Tag} from "antd";
import {ColProps} from "antd/lib/grid/col";
import {MaskedInput} from "antd-mask-input";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment, {Moment} from "moment";
import {PRONE_NUMBER_RULE, REQUIRED_RULE, RUSSIAN_PATTERN_RULE} from "../../user/utils";
import {useForm} from "antd/es/form/Form";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import PhoneNumberInput from "../../../components/phoneNumberInput";

const collProps:ColProps = {
    xs: 22,
    lg: 12,
    xl: 12,
    xxl: 12,
    span: 12
}

const fioRules = [
    REQUIRED_RULE,
    {min: 2, message: "Не может быть короче 2х букв"},
    RUSSIAN_PATTERN_RULE
]

function SpecialistRegistrationPage() {
    const [form] = useForm();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    const onConfirm = () => {
        form.validateFields()
            .then(registerUser)
            .catch(console.error)
    }

    const registerUser = (values:any) => {
        setLoading(true);
        axios.post('/rest/auth/register', {
                ...values,
            accountInfo: {
                    ...values.accountInfo,
                login: values.phoneNumber,
            }
        }).then(response => {
            setLoading(false);
            message.success("Сохранено", 5)
            window.open('https://ogoltsov.notion.site/751d2d829bd14de69080467c52e73098', '_blank');
        }).catch(response => {
            const {response: {status, data: {messages}}} = response;
            message.error(messages);
        })
            .finally(() => setLoading(false))

    }

    return (
        <Row justify="space-around" align={"middle"}>
            <Col {...collProps}>
                <Card title={"Регистрация специалиста"} loading={loading}>
                    <Form layout={"vertical"} form={form} initialValues={{personType: "EMPLOYEE", accountInfo: {iconUrl: ""}}}>
                        <Form.Item name={"personType"} hidden><Input/></Form.Item>
                        <Form.Item name={["accountInfo","iconUrl"]} hidden>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={"Фамилия"} name={"lastName"} rules={fioRules}>
                            <Input placeholder={"Фамилия"} width={"100%"}/>
                        </Form.Item>
                        <Form.Item label={"Имя"} name={"name"} rules={fioRules}>
                            <Input placeholder={"Имя"} width={"100%"}/>
                        </Form.Item>
                        <Form.Item label={"Отчество"} name={"surName"} rules={fioRules}>
                            <Input placeholder={"Отчество"} width={"100%"}/>
                        </Form.Item>
                        <Form.Item label={"Дата рождения"} name={"birthday"} rules={[
                            {required: true, message: "Выберите дату рождения"}
                        ]}>
                            <DatePicker format={"DD.MM.YYYY"} placeholder={"Дата рождения"} locale={locale}
                                        disabledDate={(date: Moment) => date.isAfter(moment())}
                                        style={{width: "100%"}}
                            />
                        </Form.Item>
                        <Form.Item name={["accountInfo","id"]} hidden><Input/></Form.Item>
                        <Form.Item name={"phoneNumber"} label={"Номер телефона"} rules={[REQUIRED_RULE, PRONE_NUMBER_RULE]}>
                            <PhoneNumberInput />
                        </Form.Item>
                        <Form.Item name={["accountInfo","password"]} label={"Пароль"} rules={[REQUIRED_RULE]}>
                            <Input type={"password"}
                                   placeholder={"Введите пароль"}/>
                        </Form.Item>
                        <br/>
                        <br/>
                        <Popconfirm title={"Вы уверены что заполнили все поля корректно?"} onConfirm={onConfirm}
                                    okButtonProps={{style: {width: "50%"}}} okText={"Да"}
                                    cancelButtonProps={{style: {width: "40%"}}} cancelText={"нет"}
                        >
                            <Button type={"primary"} style={{width: "100%"}}>Зарегистрироваться</Button>
                        </Popconfirm>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}

export default SpecialistRegistrationPage;