import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import Timesheet from "../../../model/timesheet";
import moment, {Moment} from "moment";
import Specialist from "../../../model/specialist";
import Location from "../../../model/location";
import {NoDataText} from "../../../components/utils";
import {Button, Divider, Dropdown, message, notification, Select, Space, Table, TablePaginationConfig} from "antd";
import {TimesheetStatus, TimesheetStatuses, TimesheetStatusRusName} from "../../../model/timesheetStatus";
import {Link, useLocation} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {EDIT_TIMESHEET_STATUS, GET_TIMESHEETS_WIT_FILTERS} from "../query.graphql";
import {downloadTimesheetReport} from "../download";
import {deleteTimesheet} from "../delete";
import DeleteButton from "../../../components/buttons/delete";
import {GET_FILTERS_INFO} from "./query.graphql";
import Favor from "../../../model/favor";
import {DownloadOutlined, DownOutlined} from "@ant-design/icons";
import {Typography} from "antd/es";

function onlyUniqueNums(value: number, index: any, self: number[]) {
    return self.findIndex((el: number) => el === value) === index;
}

const FILTER_DEFAULT = {
    periods: [],
    specialists: [],
    favors: [],
    locations: [],
    statuses: []
}

const PAGINATION_DEFAULT = {pageSize: 5, currentPage: 0, totalElements: 0, number: 0}

export const TimesheetsTable = (props: any) => {
    const location = useLocation();
    const [requestConf, setRequestConf] = useState({
        filters: {...FILTER_DEFAULT}, pagination: {...PAGINATION_DEFAULT}
    })
    const [updateTimesheetStatus, {loading: updatingStatus}] = useMutation(EDIT_TIMESHEET_STATUS)
    const {
        loading: fetchTimesheetLoading, data = {
            timesheetsWithFilter: {
                data: [], pageInfo: {pageSize: 5, totalPages: 1, totalElements: 5, number: 0}
            }
        }, refetch: refetchTimesheets
    } = useQuery(GET_TIMESHEETS_WIT_FILTERS,
        {
            variables: {
                filters: {...FILTER_DEFAULT},
                pageNumber: PAGINATION_DEFAULT.currentPage,
                pageSize: PAGINATION_DEFAULT.pageSize
            }
        })

    const {
        loading: fetchFilters,
        data: filtersInfo = {timesheetDates: [], favors: [], specialists: [], locations: []}
    } = useQuery(GET_FILTERS_INFO)

    useEffect(() => {
        refetchTimesheets({
            filters: requestConf.filters,
            pageNumber: requestConf.pagination.currentPage > 1 ? requestConf.pagination.currentPage - 1 : 0,
            pageSize: requestConf.pagination.pageSize
        })
    }, [requestConf])

    const editTimesheetStatus = (timesheet: Timesheet, newStatus: TimesheetStatus | string) => {
        const loading = message.loading("Сохранение...")

        const props = {variables: {props: {id: timesheet.id, status: newStatus}}}

        updateTimesheetStatus({...props})
            .then(() => setTimeout(loading, 1))
            .then(() => notification.success({message: "Сохранено"}))
    }

    const specFilters = filtersInfo.specialists
        .map(({id, fio}: Specialist) => ({
            text: `${fio}`,
            value: id
        }))

    const deleteRecord = (timesheet: Timesheet) => {
        deleteTimesheet(timesheet.id)
            .then(refetchTimesheets)
    }

    const columns: ColumnsType<any> = [
        {
            key: "periods",
            title: 'Период',
            dataIndex: 'date',
            filterMode: "tree",
            filterSearch: true,
            filters: filtersInfo.timesheetDates.map(({date}: Timesheet) => moment(date))
                .map((date: Moment) => date.year())
                .filter(onlyUniqueNums)
                .map((year: number) => ({
                    text: `${year}`, value: `${year}`,
                    children: moment.months()
                        .map((month: string) => ({
                            text: month,
                            value: moment(month, "MMM YYYY").format("DD.MM.yyyy")
                        }))
                })),
            render: (date: string, rec: Timesheet) => moment(date).format("MMMM YYYY"),
            sorter: (a: Timesheet, b: Timesheet) => moment(a.date).isSameOrBefore(moment(b.date)) ? 1 : -1
        },
        {
            key: "spec",
            title: "Специалист",
            dataIndex: ["specialist", "fio"],
            filters: specFilters,
            filterSearch: true,
            onFilter: (value, record: Timesheet) => record.specialist.id === value
        },
        {
            key: "favor",
            title: "Услуга",
            dataIndex: ["defaultFavor", "name"],
            filterSearch: true,
            filters: filtersInfo.favors.map(({id, name}: Favor) => ({
                text: name, value: id
            })),
            onFilter: (value, record: Timesheet) => record.defaultFavor?.id === value,
            render: (favorName: string, rec: Timesheet) => favorName === null ? "Нет данных" : favorName
        },
        {
            key: "location",
            title: "Место проведения занятий",
            dataIndex: ["location", "name"],
            filterSearch: true,
            filters: filtersInfo.locations.map(({id, name}: Location) => ({
                text: name, value: id
            })),
            onFilter: (value, record: Timesheet) => record.location?.id === value,
            render: (name: string, rec: Timesheet) => name === null ? (<NoDataText/>) : name
        },
        {
            key: "lessons_amount",
            align: "center",
            title: "Кол-во занятий",
            dataIndex: "completedLessonsAmount",
        },
        {
            key: "status",
            title: "Статус", width: 200,
            dataIndex: ["status"], align: "center",
            filterSearch: true,
            filters: TimesheetStatuses
                .map(status => ({text: TimesheetStatusRusName[status as keyof typeof TimesheetStatus], value: status})),
            onFilter: (value, record: Timesheet) => record.status === value,
            render: (_, record) => (
                <Select style={{width: "100%"}} value={_}
                        onChange={(value, option) => editTimesheetStatus(record, value)}>
                    {
                        TimesheetStatuses
                            .map(status => (<Select.Option key={status}
                                                           value={status}>{TimesheetStatusRusName[status as keyof typeof TimesheetStatus]}</Select.Option>))
                    }
                </Select>
            )
        },
        {
            key: "actions",
            title: "Действия",
            align: "center",
            render: (_: any, record: any) => ([
                <Link to={`/tabel/${record.id}`} key={"link_to_tabel"}>
                    <Button type={"link"} key={"btn_go_to"}
                            disabled={[TimesheetStatus.CLOSED].includes(record.status)}>Перейти</Button>
                </Link>,
                <Divider key={"div_1"} type={"vertical"}/>,
                <Dropdown disabled={record.completedLessonsAmount === 0}
                          menu={{
                              items: [
                                  {
                                      label: "Расширенный",
                                      key: "EXTENDED",
                                      onClick: () => downloadAction(() => downloadTimesheetReport(record.id, "EXTENDED"))
                                  },
                                  {
                                      label: "Простой",
                                      key: "BASIC",
                                      onClick: () => downloadAction(() => downloadTimesheetReport(record.id, "BASIC"))
                                  }
                              ],
                              selectable: true,
                              defaultSelectedKeys: ['BASIC'],
                          }}
                >
                    <Typography.Link>
                        <Space>
                            <DownloadOutlined/>
                            <DownOutlined/>
                        </Space>
                    </Typography.Link>
                </Dropdown>,
                <Divider key={"div_2"} type={"vertical"}/>,
                <DeleteButton type={"icon"} key={"delete_btn"}
                              disabled={record?.status === TimesheetStatus.CLOSED}
                              onConfirm={() => deleteRecord(record)}
                />
            ])
        }
    ]

    const downloadAction = (action: () => Promise<void>) => {
        const loading = message.loading('Загрузка...', 0);
        action()
            .finally(() => setTimeout(loading, 1))
    }

    const pagination: TablePaginationConfig = {
        pageSize: data.timesheetsWithFilter.pageInfo.pageSize,
        current: data.timesheetsWithFilter.pageInfo.number + 1,
        defaultCurrent: 1,
        total: data.timesheetsWithFilter.pageInfo.totalElements,
        position: ["bottomRight"],
        showSizeChanger: true
    }

    return <Table columns={columns} dataSource={data.timesheetsWithFilter.data} rowKey={"id"}
                  loading={fetchTimesheetLoading || updatingStatus}
                  pagination={pagination}
                  onChange={(pagination: TablePaginationConfig, filters, sorter, extra) => {
                      // @ts-ignore
                      setRequestConf(prevState => ({
                          ...prevState,
                          filters: {
                              periods: filters.periods?.map((item: any) => moment(item, "DD.MM.YYYY").format("YYYY-MM-DD")),
                              specialists: (filters.spec || []).map((item: any) => item.toString()),
                              favors: filters.favor,
                              locations: filters.location,
                              statuses: filters.status
                          },
                          pagination: {
                              ...prevState.pagination,
                              currentPage: pagination.current,
                              pageSize: pagination.pageSize
                          }
                      }))

                  }}
                  footer={() => (
                      <Link to={`/tabels/create`} state={{
                          backgroundLocation: location
                      }}>
                          <Button type={"link"}>Добавить</Button>
                      </Link>
                  )}
    />
}