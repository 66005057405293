import {gql} from "@apollo/client";

export const GET_CLIENT = gql(`query client($id: ID) {
    client(id: $id) {
        id
        lastName
        name
        surName
        insuranceNumber
        birthday
        isDeleted
        contactPerson { id lastName name surName phoneNumber description isDeleted }
        validationStatus { name rusName }
        auditedEntityInfo { 
            modifiedDate createdDate modifiedBy createdBy 
        } 
        batch { id name }
    }
}`)