import {gql} from "@apollo/client";


export const GET_LOCATIONS_BATCHES = gql(`query batches {
    locationsMainBatches {
        id name location { id name address }
    }
}`);

export const GET_CLIENTS_BY_BATCH = gql(`query clientsForBatch($id: ID) {
    clientsForBatch(batchId: $id) {
        id
        lastName
        name
        surName
        birthday
        isDeleted
        batch {id name}
        validationStatus { name rusName }
        contactPerson { id lastName name surName phoneNumber }
    }
}`);


