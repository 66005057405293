import React, {useState} from "react";
import {Button, Card, Col, Input, Row, Spin, Table, Tag} from "antd";
import {useQuery} from "@apollo/client";
import {GET_LOCATIONS} from "../../components/locations/queies.graphql";
import {CabinetLocation} from "../../model/cabinet";
import {ColumnType} from "antd/es/table";
import {Link, useNavigate} from "react-router-dom";

export const LocationsPage = (props: any) => {
    const history = useNavigate();
    const [filter, setFilter] = useState("");
    const {loading, data={locations: []}} = useQuery<{locations: CabinetLocation[]}>(GET_LOCATIONS)

    const cols:ColumnType<any>[] = [
        {
            title: "Наименование локации",
            dataIndex: "name"
        },
        {
            title: "Адрес",
            dataIndex: "address"
        },
        {
            title: "",
            render: ((value, record) => ([
                <Button type={"link"} onClick={() => history(`/admin/location/edit/${record.id}`)}>Редактировать</Button>
            ]))
        }
    ]

    const updateFilter = (e:React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value || "");

    const filteredData = (data.locations || [])
        .filter(({name}) => name.toLowerCase().includes(filter.toLowerCase()))

    return (
        <Spin spinning={loading}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Input placeholder={"Введите наименование для поиска"} style={{width: "100%"} } onInput={updateFilter} />
                </Col>
                <Col span={24}>
                    <Table dataSource={filteredData} columns={cols} loading={loading}
                           footer={() => ([
                               <Button type={"link"} onClick={() => history("/admin/location/create")}>Добавить локацию</Button>,
                           ])}
                    />
                </Col>
            </Row>
        </Spin>
    )

}