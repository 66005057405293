import React from "react";
import {Form, Input, Modal, notification, Spin, Typography} from "antd";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ModalProps} from "antd/lib/modal/Modal";
import {useForm} from "antd/es/form/Form";
import Client from "../../../model/client";
import {useMutation, useQuery} from "@apollo/client";
import {GET_TIMESHEET_LIMITS, SAVE_LIMIT} from "./query.graphql";
import TimesheetClientSelector from "../clientSelector";
import {isEmpty} from "../../../utils";

const DEFAULT_MODAL_PROPS: ModalProps = {
    title: "Добавить ограничение",
    okText: "Сохранить",
    cancelText: "Отмена",
    width: 700,
    visible: true,
}

export const AddLimitModal = () => {
    const {timesheetId} = useParams()
    const navigate = useNavigate();
    const [form] = useForm();

    const [saveLimits, {loading}] = useMutation(SAVE_LIMIT)
    const {data:{timesheetLimits} = {timesheetLimits: []}, loading: loadingLimits} = useQuery(GET_TIMESHEET_LIMITS,
        {variables: {timesheetId: timesheetId}, skip: isEmpty(timesheetId)})

    const save = (props: any) => saveLimits({
        variables: {
            limits: props.clients.map((client: Client) => ({
                person: {id: client.id},
                timesheet: {id: timesheetId}
            }))
        }
    })
        .then(() => notification.success({message: "Сохранено"}))
        .then(() => navigate(`/tabel/${timesheetId}`))
        .then(() => window.location.reload())

    return (
        <Spin spinning={loading || loadingLimits}>
            <Modal {...DEFAULT_MODAL_PROPS} onCancel={() => navigate(-1)} onOk={form.submit}>
                <Form form={form} onFinish={save}>
                    <Form.Item hidden name={["timesheet", "id"]}><Input/></Form.Item>
                    <Form.Item name={"clients"}>
                        <TimesheetClientSelector
                            placeholder={"Выберите клиентов"}
                            onSelect={(items: Client[]) => {
                                form.setFieldsValue({
                                    clients: items.map(({id}: any) => ({id}))
                                })
                            }}
                            value={(form.getFieldValue("clients")||[]).map(({id}:any) => id)}
                            filter={{timesheet: timesheetId}} mode={"multiple"}
                            exceptClients={timesheetLimits.map(({person}:any) => person)}/>
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    )
}