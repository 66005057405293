import React from "react";
import {Moment} from "moment/moment";
import {DATE_FORMAT} from "../../utils";
import {LessonStatus} from "../../../../model/lessonStatus";
import Timesheet from "../../../../model/timesheet";

export interface LessonDateColumnHeaderProps {
    date: Moment,
    timesheet: Timesheet
}

const LessonDateColumnHeader = ({date, timesheet}: LessonDateColumnHeaderProps) => {
    const lessonsAmount = timesheet.lessons
        .filter(lesson => lesson.date === date.format(DATE_FORMAT))
        .filter(lesson => lesson.status === LessonStatus.COMPLETED)
        .length

    const items = [
        <span>{date.format("DD.MM (dd)")}</span>,
        (<br/>),
        <span>{`${lessonsAmount} ${generatePostfix(lessonsAmount)}`}</span>
    ]
    return (
        <>
            {items}
        </>
    )
}

const generatePostfix = (lessonsAmount:number) => {
    const titleOptions = ['занятие', 'занятия', 'занятий']
    const n = Math.abs(lessonsAmount) % 100
    const n1 = n % 10
    if (n > 10 && n < 20) {
        return titleOptions[2]
    }
    if (n1 > 1 && n1 < 5) {
        return titleOptions[1]
    }
    if (n1 == 1) {
        return titleOptions[0]
    }
    return titleOptions[2]
}

export default LessonDateColumnHeader