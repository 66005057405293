import React from "react";
import EditLocationForm from "./form";
import {LocationType} from "./utils";
import {useMutation} from "@apollo/client";
import {SAVE_LOCATION} from "./queries.graphql";
import {useNavigate} from "react-router-dom";
import {message, notification, Spin} from "antd";

const CreateLocationForm = (props: any) => {
    const [saveLocation, {loading: savingLocation}] = useMutation(SAVE_LOCATION)
    const history = useNavigate();

    const onSave = ({name, address}:any) => {
        saveLocation({
            variables: {props: { name, address}}
        }).then(() => {
            notification.success({message: "Saved"});
            history(-1)
        }).catch(reason => {
            notification.error({message: "При сохранении возникли ошибки", description: reason.message})
        })
    }

    return (
        <Spin spinning={savingLocation}>
            <EditLocationForm entityType={LocationType.LOCATION} onSave={onSave}/>
        </Spin>
    )
}

export default CreateLocationForm;