export interface Role {
    code: RoleCode,
    rusName: RoleRusName
}

export enum RoleCode {
    ADMIN="ADMIN",
    ADMINISTRATOR="ADMINISTRATOR",
    SPECIALIST="SPECIALIST",
    CLIENT="CLIENT",
    BOT_USER="BOT_USER",
}

export enum RoleRusName {
    ADMIN="Администратор АС",
    ADMINISTRATOR="Администратор",
    SPECIALIST="Специалист",
    CLIENT="Клиент",
    BOT_USER="Робот",
}

