import React, {useEffect} from "react";
import {Col, Form, Input, InputNumber, Modal, notification, Row, Spin} from "antd";
import {ModalProps} from "antd/es";
import {useMutation, useQuery} from "@apollo/client";
import {GET_FAVOR, GetFavorQueryResult, SAVE_FAVOR} from "./quries.graphql";
import {useForm} from "antd/es/form/Form";
import {useNavigate, useParams} from "react-router-dom";

const DEFAULT_PROPS:ModalProps = {
    title: "Редактирование услуги",
    visible: true,
    width: "40%",
    okText: "Сохранить",
}

interface ParamTypes extends Record<string, string>{
    id: string
}

export const EditModal = (props: any) => {
    const [form] = useForm()
    const navigate = useNavigate();
    const {id} = useParams<ParamTypes>();
    const [saveFavor, {loading: isSaving, error}] = useMutation(SAVE_FAVOR);
    const {loading, data} = useQuery<GetFavorQueryResult>(GET_FAVOR, {
        skip: id === null || id === undefined || isNew(id),
        variables: { id }
    })

    useEffect(() => {
        form.setFieldsValue({
            ...data?.favor
            });
    }, [form, data])

    useEffect(() => {
        if (error !== undefined) {
            notification.error({ message: "При сохранении возникла ошибка", description: error.message })
        }
    }, [error])

    const save = (props: any) => {
        saveFavor({
            variables: {
                props: {
                    id: id === "new" ? null : id,
                    ...props,
                    price: Number.parseInt(props.price)

                }
            }
        }).then(() => notification.success({message: "Сохранено"}))
            .then(() => navigate("/favors", {replace: true}))
            .catch(console.error)
    }


    return (
            <Modal {...DEFAULT_PROPS}
                   title={isNew(id) ? "Создание новой услуги" : DEFAULT_PROPS.title}
                   onCancel={() => navigate(-1)} onOk={form.submit}
            >
                <Spin spinning={loading || isSaving}>
                <Form layout={"vertical"} form={form} onFinish={save} initialValues={{price: 500}}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name={"name"} label={"Наименование"} rules={[
                                {required: true, message: "Обязательно для заполнения"}
                            ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"price"} label={"Цена"} required>
                                <InputNumber  style={{width: "100%"}} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name={"description"} label={"Описание"}>
                        <Input.TextArea />
                    </Form.Item>

                </Form>
                </Spin>
            </Modal>
    )
}

const isNew = (id: string | number | undefined | null) => {
    return id === "new"
}