import React from "react";
import {Collapse, DatePicker, Descriptions} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import moment, {Moment} from "moment";
import {isDateShouldBeDisabled} from "../utils";
import {TimesheetErrors} from "./errors";
import {useTimesheetContext} from "../context";

export interface TimesheetWorkPanelProps {
    onDateSelect: any, lessonsDates: any[]
}

export const TimesheetWorkPanel = (props:TimesheetWorkPanelProps) => {
    return (
        <Collapse defaultActiveKey={1} activeKey={1} onChange={() =>{}}>
            <Collapse.Panel header={"Управление табелем"} key={1}>
                <Descriptions column={2}>
                    <DescriptionsItem label={"Добавить дату"}>
                        <LessonDatePicker {...props} />
                    </DescriptionsItem>
                    <DescriptionsItem label={"Ошибки"}>
                        <TimesheetErrors />
                    </DescriptionsItem>
                </Descriptions>
            </Collapse.Panel>
        </Collapse>
    )
}

const LessonDatePicker = ({lessonsDates, onDateSelect}:TimesheetWorkPanelProps) => {
    const {timesheet, errors, status} = useTimesheetContext()

    return (
        <DatePicker style={{width: "200px"}} mode={"date"} value={null}
                    disabled={errors.length > 0 || status === "CLOSED"} defaultPickerValue={moment(timesheet.date)}
                    disabledDate={(date: Moment) => isDateShouldBeDisabled(date, lessonsDates, timesheet.date)}
                    onChange={(date: Moment | null) => onDateSelect(date)}
        />
    )
}