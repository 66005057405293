import React from "react";
import {RenderFunction} from "antd/es/tooltip";
import {Button, message, Popover, PopoverProps} from "antd";
import {DownloadOutlined} from "@ant-design/icons";

export interface DownloadButtonProps extends Omit<PopoverProps, "title">{
    content?: React.ReactNode | RenderFunction
    disabled?: boolean
    onClick: () => Promise<any>
}

const DEFAULT_PROPS: DownloadButtonProps = {
    content: "Скачать",
    disabled: false,
    onClick: () => new Promise<any>(() => {})
}

const DownloadButton = (customProps: DownloadButtonProps = DEFAULT_PROPS) => {
    const {content, disabled, onClick} = {...DEFAULT_PROPS, ...customProps}

    const downloadAction = () => {
        const loading = message.loading('Загрузка...', 0);
        onClick()
            .finally(() => setTimeout(loading, 1))
    }

    return (
        <Popover content={content}>
            <Button key={"downloadBtn"} disabled={disabled} type={"link"} onClick={downloadAction}>
                <DownloadOutlined />
            </Button>
        </Popover>
    )
}

export default DownloadButton;