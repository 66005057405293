import Favor from "./favor";
import Client from "./client";
import Specialist from "./specialist";
import Timesheet from "./timesheet";

export interface ILesson {
    id: number | null
    date: string
    time?: string
    status: string
    favor?: Favor
    client: Client
    specialist?: Specialist | null | undefined
}

export default class Lesson implements ILesson {
    client: Client;
    date: string;
    id: number | null;
    status: string;
    favor?: Favor;
    timesheet?: Timesheet
    specialist?: Specialist | null | undefined;
    time?: string;

    constructor(id: number | null, client: Client, date: string, status: string) {
        this.client = client;
        this.date = date;
        this.id = id;
        this.status = status;
    }

    withClient(client: Client) {
        this.client = client;

        return this;
    }

    withSpecialist(spec: Specialist) {
        this.specialist = spec;
        return this;
    }

    withTime(time: string) {
        this.time = time;
        return this
    }

    withFavor(favor: Favor) {
        this.favor = favor
        return this;
    }

    withTimesheet(timesheet: Timesheet) {
        this.timesheet = timesheet;
        return this;
    }

}