import React from "react";
import {Form, FormInstance, Input, notification, Spin} from "antd";
import {LocationSelector} from "../../../components/locations/selector";
import ClientSelector from "./clientSelector";
import Client from "../../../model/client";
import {useMutation} from "@apollo/client";
import {SAVE_BATCH} from "../queries.graphql";
import Batch from "../../../model/batch";
import {RUSSIAN_PATTERN, RUSSIAN_PATTERN_RULE} from "../../user/utils";

export interface ModifyBatchFormProps {
    form: FormInstance
    mode: "SIMPLE" | "FULL"
    onAfterFinish: () => void
    batch?: Batch
    locationId: string | null
}

const ModifyBatchForm = (props: ModifyBatchFormProps) => {
    const [saveBatchMutation, {loading: isSaving, error}] = useMutation(SAVE_BATCH)
    const {form, onAfterFinish} = props;

    const saveBatch = (values: any) => {
        const options = {
            variables: {
                props: {
                    id: props.batch?.id || props.form.getFieldValue("id"),
                    name: values.name.trim(),
                    description: values.description?.trim(),
                    location: {id: values.location || props.locationId},
                    clients: (values.clients || []).map(({id}: any) => ({id}))
                }
            }
        }
        return saveBatchMutation(options)
            .then(() => {
                form.resetFields();
                onAfterFinish()
            })
            .catch((reason) => {
                const errorProps = {
                    message: "При сохранении возникли ошибки",
                    description: reason.message,
                    duration: 10
                }

                notification.error(errorProps)
            })
    }

    return (
        <Spin spinning={isSaving}>
            <Form form={form} layout={"vertical"} onFinish={saveBatch}>
                <Form.Item name={"id"} hidden><Input/></Form.Item>
                <Form.Item name={"name"} label={"Наименование"} required
                           normalize={(value, prevValue, allValues) => {
                               const lastIndex = value.length - 1
                               const prevLastIndex = lastIndex - 1

                               const isDoubleSpaces = [value[lastIndex], value[prevLastIndex]].every((val:string) => val === " ")

                               return isDoubleSpaces ? value.substring(0, prevLastIndex) : value
                           }}
                           rules={[
                               {required: true, message: "Поле обязательно для заполнения"},
                               {pattern: /^[0-9а-яА-Я ]+$/, message: "Введены недопустимые символы"},
                               {whitespace: false, message: "Введены недопустимые символы"},
                               {min: 3, message: "Не может быть короче 3х символов"},
                           ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name={"description"} label={"Описание"}>
                    <Input.TextArea/>
                </Form.Item>
                {
                    props.mode !== "SIMPLE" ? (
                        [
                            <Form.Item name={"location"} label={"Размещение"} required
                                       rules={[
                                           {required: true, message: "Локация не может быть пустой"},
                                       ]}
                            >
                                <LocationSelector value={form.getFieldValue("location")}/>
                            </Form.Item>,
                            <Form.Item name={"clients"} label={"Клиенты"}>
                                <ClientSelector showBatch={false}
                                                value={form.getFieldValue("clients") || []}
                                                placeholder={"Выберите клиентов"}
                                                onSelect={(_: any, items: Client[]) => {
                                                    form.setFieldsValue({
                                                        clients: items.map(({value}: any) => ({id: value}))
                                                    })
                                                }}
                                                exceptClients={[]}
                                />
                            </Form.Item>]
                    ) : null
                }
            </Form>
        </Spin>
    )
}

export default ModifyBatchForm