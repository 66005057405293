import {Card, Col, Row} from "antd";
import {UserProfilePage} from "../user/profile";
import TimesheetsPage from "../timesheets";
import {Role, RoleCode} from "../../model/roles";
import {useAuth} from "../../auth/authContext";

const MainPage = () => {
    return (
        <Row gutter={[16,16]}>
            <Col span={8}>
                <UserProfilePage hideTimesheet={true} />
            </Col>
            <Col span={16}>
                <TimesheetPanel/>
            </Col>
        </Row>
    )
}

const TimesheetPanel = () => {
    const {userInfo} = useAuth()

    if (!isUserSpecialist(userInfo.roles)) return null;
    else return (
        <Card title={"Табеля"}>
            <TimesheetsPage showTitle={false} id={userInfo.id} />
        </Card>
    )
}

const isUserSpecialist = (roles: Role[]) => {
    return roles.map(role => role.code)
        .includes(RoleCode.SPECIALIST)
}

export default MainPage;