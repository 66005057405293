import axios, {AxiosRequestConfig} from "axios";
import {message} from "antd";
import fileDownload from "js-file-download";

export const downloadTimesheetReport = (recordId: any, type: string):Promise<any> => {
    const config: AxiosRequestConfig = { headers: { 'Content-Type': 'multipart/form-data; boundary=boundary' }, responseType: 'blob' };
    return axios.post(`/rest/report/timesheet/${recordId}/${type}`, {}, config)
        .then((response) => {
            message.success("Операция выполнена", 3)
            fileDownload(response.data, response.headers["filename"]);
        }).catch(reason => {
        const msg = "Во время выполнения операции возникли ошибки. Обратитесь к администратору."
        message.error(msg);
        console.error(msg, reason);
    });
}