import React from "react";
import {Select} from "antd";
import {useQuery} from "@apollo/client";
import {GET_ALL_VALIDATION_STATUSES} from "./queries.graphql";
import EntityValidationStatus from "../../model/validationStatus";
import {SelectProps} from "antd/lib/select";

const DEFAULT_PROPS:SelectProps<any> = {
    placeholder: "Выберите статус валидации",
    showSearch: false,
    style: {width: "100%"}
}


const EntityValidationStatusSelector = (customProps:SelectProps<any>) => {
    const {data = {entityValidationStatuses: []}, loading} = useQuery<{entityValidationStatuses: Array<EntityValidationStatus>}>(GET_ALL_VALIDATION_STATUSES)

    return (
        <Select {...DEFAULT_PROPS} {...customProps}  >
            {
                data.entityValidationStatuses.map((item: EntityValidationStatus) => (
                    <Select.Option value={item.name}>
                        {item.rusName}
                    </Select.Option>
                ))
            }
        </Select>
    )
}

export default EntityValidationStatusSelector