import React from "react";

import {Avatar, Comment, notification, Spin} from "antd";
import {Message, MessageStatus} from "../../../model/message";
import {Person} from "../../../model/person";
import {useMutation} from "@apollo/client";
import {UPDATE_TIMESHEET_MESSAGE_STATUS} from "./query.graphql";
import {useAuth} from "../../../auth/authContext";
import moment from "moment";

const AppComment: React.FC<{onEdit: () => void, comment:Message}> = ({comment, onEdit}) => {
    const {userInfo} = useAuth()
    const [updateStatus, {loading}] = useMutation(UPDATE_TIMESHEET_MESSAGE_STATUS)

    const makeMessageRead = () => {
      updateStatus(
          {variables: { props: { id: comment.id, status: MessageStatus.READ } }}
      )
          .then(onEdit)
          .then(() => notification.success({message: "Сохранено"}))
    }

    const actions = comment.status === MessageStatus.SENT && comment.sender.id !== userInfo.id
        ? [<span onClick={makeMessageRead} key="comment-nested-reply-to">Отметить прочитанным</span>]
        : []

    return (
        <Spin spinning={loading}>
            <Comment
                datetime={moment(comment.auditedInfo.createdDate).format("DD.MM.YYYY HH:mm")}
                actions={actions}
                author={getFio(comment.sender)}
                avatar={<Avatar src={comment.sender.accountInfo.iconUrl} alt={getFio(comment.sender)}/>}
                content={comment.message}
            />
        </Spin>
    );
}

const getFio = (sender: Person) => {
    return `${sender.lastName} ${sender.name} ${sender.surName || ""}`
}

export {AppComment as Comment}