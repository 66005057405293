import {gql} from "@apollo/client";

const GET_LOCATION = gql(`query location($id: ID) {
    location(id: $id) {
        id
        name
        address
    }
}`)

const SAVE_LOCATION = gql(`mutation saveLocation($props:LocationInput){
    saveLocation(props: $props) {
        id
    }
}`);


export {SAVE_LOCATION, GET_LOCATION};

