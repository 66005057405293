import React from "react";
import {Tabs, TabsProps} from "antd";
import {ClientsListPage} from "./list";
import {ClientsListByGroupsPage} from "./byGroups";

const items: TabsProps['items'] = [
    {
        key: "BY_BATCHES",
        label: "По группам/локациям",
        children: <ClientsListByGroupsPage />
    },
    {
        key: "LIST",
        label: "Все клиенты",
        children: <ClientsListPage />
    }
];

export const ClientsPage = () => {
    return (<Tabs defaultActiveKey="1" items={items}/>)
}