import React, {useEffect} from "react";
import {
    Col,
    Collapse,
    DatePicker,
    Descriptions,
    Form,
    Input,
    Modal,
    notification,
    Row,
    Spin
} from "antd";
import {ModalProps} from "antd/lib/modal/Modal";
import locale from "antd/es/date-picker/locale/ru_RU";
import {useMutation, useQuery} from "@apollo/client";
import {SAVE_CLIENT} from "./query.graphql";
import {useForm} from "antd/es/form/Form";
import Title from "antd/es/typography/Title";
import moment, {Moment} from "moment";
import {GET_CLIENT} from "../query.graphql";
import {isEmpty} from "../../../utils";
import Client from "../../../model/client";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import PageMode from "../../../model/pageMode";
import {CreatedBy, ModifyBy} from "../../../components/utils/createdBy";
import EntityValidationStatusSelector from "../../../components/entityValidationStatus/selector";
import EntityValidationStatus from "../../../model/validationStatus";
import {GET_ALL_VALIDATION_STATUSES} from "../../../components/entityValidationStatus/queries.graphql";
import {useAuth} from "../../../auth/authContext";
import {RoleCode} from "../../../model/roles";
import {BatchSelector} from "../../../components/batches/selector";
import {PRONE_NUMBER_RULE, REQUIRED_RULE, RUSSIAN_PATTERN_RULE} from "../../user/utils";
import {MaskedInput} from "antd-mask-input";
import {TIMESHEET_INITIAL} from "../../timesheet/utils";
import Timesheet from "../../../model/timesheet";
import {GET_TIMESHEET} from "../../timesheet/timesheet/query.graphql";
import {ContactPersonConfigurator} from "../contactPerson";
import PhoneNumberInput from "../../../components/phoneNumberInput";
import {useTimesheetBatchesContext} from "../../timesheet/batches/context";

export interface EditClientProps extends ModalProps {
    clientId: string | number | null | undefined,
}

const DEFAULT_MODAL_PROPS: ModalProps = {
    title: "Информация о клиенте",
    okText: "Сохранить",
    cancelText: "Отмена",
    width: 700
}

export interface ClientQueryResult {
    client: Client
}

const INITIAL_FORM = {
    personType: "CLIENT",
    validationStatus: "NEED_VALIDATE",
}

const EditClientModal = () => {
    const [form] = useForm()
    const history = useNavigate();
    const {state} = useLocation()
    const params = useParams<{ id: string, mode: PageMode }>()
    const [saveClient, {loading: isClientSaving, error: errorsInSaving}] = useMutation(SAVE_CLIENT)
    const existClientInfo = useQuery<ClientQueryResult>(GET_CLIENT, {
        variables: {id: params.id},
        skip: isEmpty(params.id) || params.mode === PageMode.CREATE
    })
    const {data: _ = {entityValidationStatuses: []}} = useQuery<{ entityValidationStatuses: Array<EntityValidationStatus> }>(GET_ALL_VALIDATION_STATUSES)
    console.info('state', state)
    const {
        loading,
        data = {timesheet: TIMESHEET_INITIAL}
    } = useQuery<{ timesheet: Timesheet }>(
        GET_TIMESHEET, {
            variables: {id: state?.timesheet?.id},
            skip: state?.timesheet === null || state?.timesheet === undefined
        }
    );

    useEffect(() => {
        if (existClientInfo?.data?.client !== undefined) {
            const item = {
                ...INITIAL_FORM,
                id: existClientInfo.data.client.id,
                name: existClientInfo.data.client.name,
                lastName: existClientInfo.data.client.lastName,
                surName: existClientInfo.data.client.surName,
                insuranceNumber: existClientInfo?.data?.client?.insuranceNumber,
                phoneNumber: existClientInfo.data.client?.contactPerson?.phoneNumber,
                birthday: existClientInfo.data.client.birthday != null ? moment(existClientInfo.data.client.birthday) : null,
                isDeleted: existClientInfo.data.client.isDeleted,
                batch: existClientInfo.data.client.batch.map(b => b.id),
                personType: existClientInfo.data.client.personType || "CLIENT",
                validationStatus: existClientInfo.data.client.validationStatus.name || "NEED_VALIDATE",
                contactPerson: {
                    id: existClientInfo.data.client?.contactPerson?.id,
                    iconUrl: "",
                    isDeleted: existClientInfo.data.client?.contactPerson?.isDeleted || false,
                    lastName: existClientInfo.data.client?.contactPerson?.lastName,
                    name: existClientInfo.data.client?.contactPerson?.name,
                    surName: existClientInfo.data.client?.contactPerson?.surName || "",
                    phoneNumber: existClientInfo.data.client?.contactPerson?.phoneNumber,
                    description: existClientInfo.data.client?.contactPerson?.description,
                    personType: existClientInfo.data.client?.contactPerson?.personType || "CONTACT_PERSON",
                }
            }
            form.setFieldsValue(item)
        }
    }, [existClientInfo.data?.client])

    const onFormOk = () => {
        form.validateFields()
            .then((result: any) => {
                const vars = {
                    client: {
                        ...result,
                        lastName: result?.lastName?.trim(),
                        name: result?.name?.trim(),
                        surName: result?.surName?.trim(),
                        birthday: result?.birthday?.format("YYYY-MM-DD"),
                        batch: (result.batch || []).map((id: string) => ({id})),
                        validationStatus: result?.validationStatus || "NEED_VALIDATE"
                    }
                }
                saveClient({
                    variables: vars
                }).then(() => {
                    notification.success({message: "Сохранено"});
                    form.resetFields()
                    history(-1);
                })
                    .catch((reason) => notification
                        .error({message: "При сохранении возникли ошибки", description: reason.message, duration: 15}))
            })
            .catch(((reason: any) => {
                notification.error({message: "При сохранении возникли ошибки", description: "Заполните поля коректно"})
            }))
    }

    return (<Modal {...DEFAULT_MODAL_PROPS} onCancel={() => history(-1)} open={true} onOk={onFormOk}
                   okButtonProps={{loading: isClientSaving}}
                   cancelButtonProps={{disabled: isClientSaving}}
        >
            <Spin spinning={isClientSaving || existClientInfo.loading}>
                <Form layout={"vertical"} form={form} initialValues={INITIAL_FORM}>
                    <Form.Item name={"id"} hidden><Input/></Form.Item>
                    <Form.Item name={"personType"} hidden><Input/></Form.Item>
                    <Row gutter={16 * 2} style={{alignItems: 'center'}}>
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item label={"Фамилия"} name={"lastName"} rules={[
                                        {required: true, message: "Введите фамилию клиента"},
                                        {min: 2, message: "Фамилия не может быть короче 2х букв"},
                                        RUSSIAN_PATTERN_RULE
                                    ]}>
                                        <Input placeholder={"Введите фамилию клиента"} width={"100%"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={"Имя"} name={"name"} rules={[
                                        {required: true, message: "Введите имя клиента"},
                                        {min: 2, message: "Имя не может быть короче 2х букв"},
                                        RUSSIAN_PATTERN_RULE
                                    ]}>
                                        <Input placeholder={"Введите имя клиента"}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label={"Отчество"} name={"surName"} rules={[
                                        {min: 2, message: "Отчество не может быть короче 2х букв"},
                                        RUSSIAN_PATTERN_RULE
                                    ]}>
                                        <Input placeholder={"Введите Отчество клиента"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={"Дата рождения"} name={"birthday"}>
                                        <DatePicker format={"DD.MM.YYYY"} placeholder={"Дата рождения"} locale={locale}
                                                    disabledDate={(date: Moment) => date.isAfter(moment())}
                                                    style={{width: "100%"}}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item label={"Номер СНИЛС"} name={"insuranceNumber"}
                                               rules={[
                                                   {
                                                       min: 11, message: "СНИЛС не может быть короче 11 символов",
                                                       transform: value => value.replace(/\D/g, "")
                                                   },
                                               ]}
                                    >
                                        <MaskedInput mask={"000-000-000-00"} placeholder={"Введите СНИЛС клиента"}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={["contactPerson","id"]} hidden><Input /></Form.Item>
                                    <Form.Item name={["contactPerson","personType"]} hidden><Input value={"CONTACT_PERSON"} /></Form.Item>
                                    <Form.Item name={["contactPerson", "phoneNumber"]} label={"Номер телефона"}
                                               rules={[PRONE_NUMBER_RULE, REQUIRED_RULE]}>
                                        <PhoneNumberInput />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label={"Группы"} name={"batch"}
                                               rules={[
                                                   {required: true, message: "Выберите группу сада, в которой числится клиент"},
                                               ]}
                                    >
                                        <BatchSelector showPrimary={true} locationId={
                                            data?.timesheet?.location?.id || state.location
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <ContactPersonConfigurator form={form}/>
                        </Col>
                    </Row>
                    <br/>
                    <ValidationBlock/>
                </Form>
                <MetaInfoBlock existClient={existClientInfo.data?.client}/>
                <Row>
                    <Col>
                        {errorsInSaving?.graphQLErrors ? (<Title level={5} type={"danger"}>Ошибки</Title>) : null}
                        <li>
                            {errorsInSaving?.graphQLErrors?.map(error => (<ul>{error?.message}</ul>))}
                        </li>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    )
}

const ValidationBlock = () => {
    const {userInfo} = useAuth()
    const isAdministrator = userInfo.roles.map(role => role.code).includes(RoleCode.ADMINISTRATOR)

    if (!isAdministrator) return null

    return (
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Form.Item label={"Статус валидации"} name={["validationStatus"]}>
                    <EntityValidationStatusSelector/>
                </Form.Item>
            </Col>
        </Row>
    )
}

const MetaInfoBlock = ({existClient}: { existClient: Client | undefined }) => {
    const {userInfo} = useAuth()
    const isAdministrator = userInfo.roles.map(role => role.code).includes(RoleCode.ADMINISTRATOR)

    if (!isAdministrator) return null

    return (
        <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel header="META информация" key="1">
                <Descriptions column={2}>
                    <Descriptions.Item label="Cоздал">
                        <CreatedBy id={existClient?.auditedEntityInfo.createdBy}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="Редактировал">
                        <ModifyBy id={existClient?.auditedEntityInfo.modifiedBy}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="Создан">
                        <span>{moment(existClient?.auditedEntityInfo.createdDate).format("DD.MM.YYYY HH:mm")}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Редактирован">
                        <span>{moment(existClient?.auditedEntityInfo.modifiedDate).format("DD.MM.YYYY HH:mm")}</span>
                    </Descriptions.Item>
                </Descriptions>
            </Collapse.Panel>
        </Collapse>
    )
}
EditClientModal.whyDidYouRender = false;
export default EditClientModal

