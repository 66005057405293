import React from "react";
import {Button, Col, Row} from "antd";
import {TimesheetsTable} from "./table";


const AdminTimesheetsPage = () => {
    return (
        <Row gutter={16}>
            <Col span={24} style={{display: "block"}}>
                <TimesheetsTable />
            </Col>
        </Row>
    )
}

export default AdminTimesheetsPage;