import React, {useEffect, useState} from "react";
import {Card, Checkbox, Col, Form, Input, Radio, Row} from "antd";
import {PersonSelector} from "../../../components/personSelector/selector";
import {MaskedInput} from "antd-mask-input";
import {PRONE_NUMBER_RULE, REQUIRED_RULE, RUSSIAN_PATTERN_RULE} from "../../user/utils";
import PhoneNumberInput from "../../../components/phoneNumberInput";

enum ContactPersonModeItem {NOT_INFO = "NOT_INFO", EXIST="EXIST", CREATE_NEW="CREATE_NEW"}

export const ContactPersonConfigurator = ({form}: any) => {
    const [mode, setMode] = useState<ContactPersonModeItem>(ContactPersonModeItem.NOT_INFO);

    useEffect(() => {
            form.setFieldsValue({
                contactPerson: ContactPersonModeItem.NOT_INFO === mode ? null : {id: null}
            })
    }, [mode])

    const uiMode = {
        [ContactPersonModeItem.NOT_INFO]: null,
        [ContactPersonModeItem.EXIST]: (<PersonSelector />),
        [ContactPersonModeItem.CREATE_NEW]: <NewContactPersonForm/>
    }

    return (
        <Card title={"Контактное лицо"} style={{width: "100%"}} size={"small"}>
            <Row gutter={[16, 16]}>
               <Col span={24}>
                   <Radio.Group style={{width: "100%"}} value={mode}
                                onChange={e => setMode(e.target.value)}
                   >
                       <Radio.Button style={{width: "25%"}} value={ContactPersonModeItem.NOT_INFO}>Нет информации</Radio.Button>
                       <Radio.Button style={{width: "50%"}} value={ContactPersonModeItem.EXIST}>Выбрать из существующих</Radio.Button>
                       <Radio.Button style={{width: "25%"}} value={ContactPersonModeItem.CREATE_NEW}>Создать нового</Radio.Button>
                   </Radio.Group>
               </Col>
               <Col span={24}>
                   {
                       uiMode[mode]
                   }
               </Col>
            </Row>
        </Card>
    )
}

const NewContactPersonForm = () => {
    return (
            <Col span={24} style={{width: "100%"}}>
                <Row gutter={16}>
                    <Form.Item name={["contactPerson","id"]} hidden><Input /></Form.Item>
                    <Form.Item name={["contactPerson","iconUrl"]} hidden>
                        <Input value={""}  />
                    </Form.Item>
                    <Form.Item name={["contactPerson","personType"]} hidden><Input value={"CONTACT_PERSON"} /></Form.Item>
                    <Form.Item name={["contactPerson","validationStatus"]} hidden><Input value={"NEED_VALIDATE"} /></Form.Item>
                    <Form.Item name={["contactPerson","iconUrl"]} hidden><Input /></Form.Item>
                    <Form.Item name={["contactPerson","canAuth"]} hidden><Checkbox value={false}/></Form.Item>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item name={["contactPerson","lastName"]} label={"Фамилия"}
                                           rules={[REQUIRED_RULE, RUSSIAN_PATTERN_RULE]}>
                                    <Input placeholder={"Введите фамилию"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={["contactPerson","name"]} label={"Имя"} rules={[REQUIRED_RULE, RUSSIAN_PATTERN_RULE]}>
                                    <Input placeholder={"Введите имя"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={["contactPerson","surName"]} label={"Отчество"} rules={[RUSSIAN_PATTERN_RULE]}>
                                    <Input placeholder={"Введите отчество"}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={["contactPerson","phoneNumber"]} label={"Номер телефона"}
                                           rules={[REQUIRED_RULE, PRONE_NUMBER_RULE]}>
                                    <PhoneNumberInput />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={["contactPerson","description"]} label={"Краткое описание"}>
                                    <Input.TextArea style={{width: "100%"}} placeholder={"Доп. сведения"}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
    )
}

