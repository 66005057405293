import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_PERSON_INFO} from "../../pages/user/profile/queries.graphql";
import {INITIAL_USER_INFO} from "../../pages/user/utils";
import {Spin, Tooltip} from "antd";
import {isEmpty} from "../../utils";

interface EntityCreatedByProps {
    id: string | null | undefined
}

const NO_DATA = <span>Нет данных</span>

const EntityCreatedBy = ({id}: EntityCreatedByProps) => {
    const [fio, setFio] = useState({short: "", full: ""});

    const {data:{person} = {person: INITIAL_USER_INFO}, loading} = useQuery(GET_PERSON_INFO, {
        variables: {
            id: id
        }, skip: isEmpty(id)
    });

    useEffect(() => {
        const shortName = (person?.name || "").length > 1 ? person?.name.substr(0, 1) + "." : ""
        const shortSurname = (person?.surName || "").length > 1 ? person?.surName.substr(0, 1) + "." : ""

        setFio({
            ...fio,
            short: `${person?.lastName} ${shortName} ${shortSurname}`,
            full: `${person?.lastName} ${person?.name} ${person?.surName || ""}`
        })
    }, [person])

    return (
        <Spin spinning={loading}>
            {
                isEmpty(id) ? NO_DATA : (
                    <Tooltip title={fio.full}>
                        <span>{fio.short}</span>
                    </Tooltip>
                )
            }
        </Spin>
    )
}

export {
    EntityCreatedBy as CreatedBy,
    EntityCreatedBy as ModifyBy
}