import {useForm} from "antd/es/form/Form";
import {useAuth} from "../../auth/authContext";
import React, {useState} from "react";
import {Button, Form, Input, notification, Spin, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {MaskedInput} from "antd-mask-input";
import PhoneNumberInput from "../../components/phoneNumberInput";

interface AuthFormProps {
    username: string
    password:string
    remember: boolean
}

export const LoginForm = () => {
    const [form] = useForm()
    const history = useNavigate()
    const {login} = useAuth()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [authErrors, setAuthErrors] = useState<string[]>([])
    const onFinish = (formProps: AuthFormProps) => {
        setLoading(true)
        login(formProps.username, formProps.password)
            .then(() => {
                notification.success({message: "Авторизация успешна"})
                history("/", {replace: true});
                window.location.reload()
            })
            .catch((response ) => {
                const {response: {status, data: {messages}}} = response;

                if (status === 500) {
                    notification.error({message: "Ошибка авторизации", description: "Сервис временно недоступен, попробуйте позже"})
                } else {
                    notification.error({message: "Ошибка авторизации", description: "Введите корректные логин и пароль"})
                }
                setAuthErrors(messages || [])
            })
            .finally(() => setLoading(false))
    };

    return (
        <Spin spinning={isLoading}>
            <Form
                form={form}
                name="basic"
                layout={"vertical"}
                initialValues={{
                    username: "",
                    password: "",
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Номер телефона"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Введите номер телефона',
                        },
                        {
                            type: "string",
                            pattern: /^(\+7 \d{3} \d{3} \d{4})$/,
                            message: "Не соответствует шаблону"
                        }
                    ]}
                >
                    <PhoneNumberInput />
                </Form.Item>
                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Введите пароль',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                {
                    [].length === 0
                        ? null
                        : authErrors.map(err => (<Typography.Text type="danger">{err}</Typography.Text>))
                }
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{width: "100%"}}>
                        Авторизоваться
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    )
}