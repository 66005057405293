import {gql} from "@apollo/client";
import Favor from "../../model/favor";

export interface GetFavorQueryResult {
    favor: Favor
}

export const GET_FAVOR = gql(`query favor($id: ID) {
    favor(id: $id) {
        id
        name
        price
        description
    }
}`)

export const SAVE_FAVOR = gql(`mutation saveFavor($props: FavorInput) {
    saveFavor(props: $props) {
        id
    }
}`)