import React, {useEffect} from "react";
import {Button, Form, Input, notification, Popconfirm, Spin} from "antd";
import {LocationSelector} from "../../components/locations/selector";
import {useForm} from "antd/es/form/Form";
import {useNavigate, useParams} from "react-router-dom";
import {LocationType} from "./utils";
import {NotFoundPage} from "../error";
import PageMode from "../../model/pageMode";
import {NewLocationFormProps} from "./index";

const requiredRule = {required: true, message: "Обязательно для заполнения"}

interface EditLocationFormProps {
    onSave: (fields: any) => void
    onDelete?: () => void
    initialValues?: any
    entityType?: LocationType
}

const EditLocationForm = ({onSave, initialValues, entityType, onDelete}:EditLocationFormProps) => {
    const [form] = useForm();
    const history = useNavigate();
    const {mode} = useParams<NewLocationFormProps>();
    const onCanceled = () => { form.resetFields(); history(-1);}

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues])
    if (initialValues === null) return <NotFoundPage message={"Такой сущности не существует"}/>
    return (
            <Form form={form} onFinish={onSave} initialValues={initialValues} layout={"vertical"}>
                <Form.Item name={"id"} hidden ><Input disabled/></Form.Item>
                <Form.Item name={"name"} label={"Наименование"} rules={[requiredRule]}><Input/></Form.Item>
                {
                    entityType === LocationType.LOCATION ? [<AddressFormItem/>] : null
                }

                <Button type={"link"} onClick={form.submit}>Сохранить</Button>
                <Button type={"link"} onClick={onCanceled}>Отмена</Button>
                {
                    (mode === PageMode.EDIT && onDelete !== undefined)
                        ? <DeleteButton onConfirm={() => onDelete()} />
                        : null
                }
            </Form>
    );
}

const AddressFormItem = () => <Form.Item name={"address"} label={"Адрес"} rules={[requiredRule]}><Input /></Form.Item>
const DeleteButton = ({onConfirm}:any) =>
    <Popconfirm title={"Вы уверены что хотите удалить?"} onConfirm={onConfirm}>
        <Button type={"link"}>Удалить</Button>
    </Popconfirm>

export default EditLocationForm

