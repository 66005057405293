import React from "react";
import {DELETE_LINE_ITEM_ID} from "../../utils";
import TimesheetClientSelector, {OptionType} from "../../clientSelector";
import {Typography} from "antd";
import Client from "../../../../model/client";
import {useTimesheetContext} from "../../context";
import Timesheet from "../../../../model/timesheet";

export interface ClientFioHeaderProps {
    client: Client
    timesheet: Timesheet
    onAddClients: (clients: Client[]) => void
}

const ClientFioHeader = ({client, timesheet, onAddClients}:ClientFioHeaderProps) => {
    const context = useTimesheetContext()

    const indexInLimitList = context.limits
        .map((limit:any) => limit.person)
        .findIndex((value:any) => value.id === client.id)

    if (client.id === DELETE_LINE_ITEM_ID) {
        return (
            <TimesheetClientSelector placeholder={"Добавить клиента/группу"} key={`client_selector_${client.id}`}
                                     onSelect={onAddClients} filter={{timesheet: context.timesheet.id}}
                                     exceptClients={timesheet.clients}
                                     optionsTypes={[OptionType.CLIENT, OptionType.BATCH]}
            />
        )
    }

    const isClientInLimitList = indexInLimitList !== -1
    const elements = [
        <Typography.Text disabled={isClientInLimitList}>
            {client.fio}
        </Typography.Text>
    ]

    if (isClientInLimitList) {
        elements.push(
            (<br/>), (<Typography.Text type={"danger"}>Не брать на занятия</Typography.Text>)
        )
    }
    return (<>{elements}</>);
}

export default ClientFioHeader;