import React from "react";
import {Select, Spin} from "antd";
import {SelectProps} from "antd/lib/select";
import {useQuery} from "@apollo/client";
import {GET_PERSONS} from "./queries.graphql";

const DEFAULT_PROPS:SelectProps = {
    placeholder: "Выберите пользователя",
    style: {width: "100%"}
}

export const PersonSelector = (props: SelectProps) => {
    const {loading, data= {persons: []}} = useQuery(GET_PERSONS)

    return (
        <Spin spinning={loading}>
            <Select loading={loading} {...DEFAULT_PROPS} {...props} >
                {
                    data.persons.map(({id, name, lastName, surName}:any) => (<Select.Option value={id}>{lastName} {name} {surName || ""}</Select.Option>))
                }
            </Select>
        </Spin>
    )
}