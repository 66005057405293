import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Input, Modal, notification, Row, Spin, Table, Tag, Typography} from "antd";
import TimesheetHeader from "../header";
import {TABLE_LOCALE} from "../../../components/table/locale";
import {useNavigate} from "react-router-dom";
import moment, {Moment} from "moment";
import Timesheet, {TimesheetNote} from "../../../model/timesheet";
import {
    DATE_FORMAT,
    DELETE_LINE_ITEM_ID,
    onlyUniqueBatch,
    onlyUniqueClient,
    onlyUniqueDates,
    TIMESHEET_INITIAL
} from "../utils";
import {useMutation} from "@apollo/client";
import {DELETE_LESSONS_MUTATION, SAVE_TIMESHEET_MUTATION} from "./query.graphql";
import Client from "../../../model/client";
import Lesson from "../../../model/lesson";
import {LessonStatus} from "../../../model/lessonStatus";
import {ColumnType} from "antd/lib/table/interface";
import DeleteButton from "../../../components/buttons/delete";
import Batch from "../../../model/batch";
import {NoDataText} from "../../../components/utils";
import {TimesheetStatus} from "../../../model/timesheetStatus";
import {useTimesheetContext} from "../context";
import LessonCheckbox from "./components/checkbox";
import DeleteClientButton from "./components/deleteClientButton";
import ClientFioHeader from "./components/clientFioHeader";
import LessonDateColumnHeader from "./components/lessonsColumnHeader";

export const TimesheetTab = () => {
    const context = useTimesheetContext()
    const navigate = useNavigate();
    const [lessonsDates, setLessonsDates] = useState<Moment[]>([])
    const [isChanged, markChanged] = useState<Boolean>(false);
    const [columns, setColumns] = useState<any[]>([]);
    const [timesheet, setTimesheet] = useState<Timesheet>(TIMESHEET_INITIAL)
    const [modal, contextHolder] = Modal.useModal();

    const [saveTimesheetMutation, {loading: isTimesheetSaving}] = useMutation(SAVE_TIMESHEET_MUTATION)
    const [deleteLessonsMutation, {loading: isLessonsDeleting}] = useMutation(DELETE_LESSONS_MUTATION)

    useEffect(() => {
        if (isChanged) {
            context.setWarnings([
                ...context.warnings,
                "Табель был изменен. Не забудьте сохранить"
            ])
        }

    }, [isChanged])

    useEffect(() => {
        const notes:TimesheetNote[] = context.timesheet.notes.map((item) => ({
            id: item.id,
            note: item.note,
            client: {id: item.client.id} as Client,
            timesheet: {id: context.timesheet.id} as Timesheet
        }))

        setTimesheet(prevState => ({
            ...prevState,
            status: context.timesheet.status,
            specialist: {id: context.timesheet.specialist.id},
            lessons: context.timesheet.lessons,
            notes: [...prevState.notes, ...notes]
        }))

        const dates = context.timesheet.lessons
            .map((it:Lesson)=> moment(it.date))
            .filter(onlyUniqueDates)
            .sort((date1:Moment, date2:Moment) => date1.isAfter(date2) ? 1: -1)

        if (dates.length > 0) {
            setLessonsDates(_ => [...dates])
        }
    }, [context.timesheet])

    const generateLessonsForClient = (client: Client) => lessonsDates
        .map(date =>
            new Lesson(null, client, date.format(DATE_FORMAT), LessonStatus.AWAIT)
                .withTimesheet(timesheet)
        )

    const addClients = (newClients: Client[]):void => {
        const newLessons = newClients.flatMap(generateLessonsForClient)

        setTimesheet(prevState => ({
            ...prevState,
            clients: [
                ...timesheet.clients,
                ...newClients
            ],
            lessons: [...prevState.lessons, ...newLessons]
        }))
        markChanged(true);
    }

    const deleteLessons = (lessons: any[]):Promise<any> => {
        if (lessons?.length === 0) return new Promise(() => {});

        markChanged(true);

        return deleteLessonsMutation({
            variables: {
                lessons: lessons
            }
        })
    }

    const deleteClientFromTimesheet = (clientToDelete: Client):void => {
        const lessonsToDelete = timesheet.lessons
            .filter(lesson => lesson.id !== null)
            .filter(lesson => lesson.client.id === clientToDelete.id)
            .map((lesson) => ({id: lesson.id}))

        deleteLessons(lessonsToDelete).then(() => notificationSuccess());

        const updatedClients = timesheet.clients.filter(client => client.id !== clientToDelete.id)
        const updatedLessons = timesheet.lessons.filter(lesson => lesson.client.id !== clientToDelete.id)

        setTimesheet(prevState => ({
            ...prevState,
            clients: updatedClients,
            lessons: updatedLessons
        }))
    }

    const notificationSuccess = (message?: string | undefined) => {
        if (message) {
            notification.success({message})
        } else {
            notification.success({message: "Занятия удалены"})
        }
    }

    const onCheckboxClick = (checked: Boolean, client: Client, lesson?:Lesson) => {
        const lessons = [...timesheet.lessons]

        const index = lessons.findIndex(item => (item.client.id === lesson?.client.id) && (item.date === lesson.date))

        if (index != -1) {
            lessons[index].status = checked? LessonStatus.COMPLETED : LessonStatus.AWAIT
        }

        setTimesheet(prevState => ({
            ...prevState,
            lessons: lessons
        }))
        markChanged(true);
    }
    const deleteLessonsForDate = (date: Moment) => {
        setLessonsDates(prev =>
            prev.filter((d:Moment)=> d.format(DATE_FORMAT) !== date.format(DATE_FORMAT))
        );

        const lessonsToDelete = timesheet.lessons
            .filter((lesson)=> lesson.id !== null)
            .filter((lesson)=> lesson.date === date.format(DATE_FORMAT))
            .map((lesson) => ({id: lesson.id}))

        if (lessonsToDelete.length > 0) {
            deleteLessons(lessonsToDelete).then(() => notificationSuccess())
        }

        setTimesheet(prevState => ({
            ...prevState,
            lessons: timesheet.lessons.filter(lesson => lesson.date !== date.format(DATE_FORMAT))
        }))

    }

    useEffect(() => {
        const cols:ColumnType<any>[] = [
            {
                fixed: "left",
                width: 50,
                render: (_:any, record: Client) => (<DeleteClientButton client={record} onDelete={deleteClientFromTimesheet} />)
            },
            {
                title: "Клиент", width: 300, fixed: true,
                sorter: (a:Client, b:Client) => a.fio && b.fio ? a.fio.localeCompare(b.fio) : 0,
                render: (_:any, record: Client):JSX.Element => (<ClientFioHeader client={record} timesheet={timesheet} onAddClients={addClients}/>)
            },
            {
                title: "Группы",
                width: 100, align: "center",
                dataIndex: "batch", fixed: true,
                filters: [
                    ...context.timesheet.clients
                        .flatMap((client: Client) => client.batch)
                        .filter(onlyUniqueBatch)
                        .map(({id, name}:Batch) => ({text: name, value: id}))
                ],
                onFilter: ((value, rec: Client) => {
                    if (rec.id === DELETE_LINE_ITEM_ID) return true

                    return rec.batch.findIndex( ({id}) => id === value ) > -1
                }),
                render: (val: any, rec: any) => {
                    if (val && val.length > 0) {
                        return val.map(({name}:any) => <Tag>{name}</Tag>)
                    }
                    return rec.id === DELETE_LINE_ITEM_ID ? null : (<NoDataText/>)
                }
            },
            {
                title: "Кол-во занятий",
                width: 100, align: "center", fixed: true,
                render: (val, rec) => {
                    const isClientRecord = rec.id === DELETE_LINE_ITEM_ID
                    const completedLessons = timesheet.lessons
                        .filter(lesson => isClientRecord || lesson.client.id === rec.id)
                        .filter(lesson => lesson.status === LessonStatus.COMPLETED)

                    return completedLessons.length;
                }
            }
        ];

        const lessonsDatesColumns:ColumnType<any>[] = lessonsDates
            .map((date: Moment, idx) => {
                return {
                    title: <LessonDateColumnHeader date={date} timesheet={timesheet} /> ,
                    align: "center", width: 120,
                    render: (_: any, client: Client) => {
                        if (client.id === DELETE_LINE_ITEM_ID)
                            return (<DeleteButton key={idx} type={"icon"} onConfirm={() => deleteLessonsForDate(date)} />)

                        return (<LessonCheckbox key={idx} date={date} timesheet={timesheet} client={client} onChange={onCheckboxClick} />)

                    }
                }
            })
        cols.push(...lessonsDatesColumns);
        setColumns(cols);
    }, [timesheet])

    useEffect(() => {
        const _clients = [...context.timesheet.clients, ...timesheet.clients]
            .filter(onlyUniqueClient)

        if (_clients.length > 0) {
            setTimesheet(prevState => ({
                ...prevState, clients: _clients, defaultFavor: context.timesheet.defaultFavor
            }))
        }
    }, [lessonsDates])

    const addColumn = (date: Moment | null) => {
        if (date === null) return;
        markChanged(true);

        const newDateLessons = timesheet.clients
            .map(client =>
                new Lesson(null, client, date.format(DATE_FORMAT),LessonStatus.AWAIT)
                    .withTimesheet(timesheet)
            );

        setTimesheet(prevState => ({
            ...prevState,
            lessons: [...prevState.lessons, ...newDateLessons]
        }))

        setLessonsDates(prev => [...prev, date]
            .filter(onlyUniqueDates)
            .sort((date1:Moment, date2:Moment) => date1.isAfter(date2) ? 1: -1)
        )
    }

    const modalProps = {
        title: "Отправка табеля на согласование",
        content: (<>
            <Typography.Text>После отправки табеля на согласование </Typography.Text>
            <Typography.Text strong={true} type={"danger"}>Вы не сможете редактировать табель.</Typography.Text>
            <br/><br/>
            <Typography.Text strong={true}>Подтвердите свое намерение!</Typography.Text>
        </>),
        okText: "Подтверждаю",
        onOk: () => {
            timesheet.status = TimesheetStatus.ON_APPROVAL
            saveTimesheet()
            navigate(-1)
        }
    }

    const saveTimesheetAndSend = () => {
        modal.confirm(modalProps)
    }

    const saveTimesheet = () => {
        const getEnrichedLesson = (lesson: Lesson) => ({
            id: lesson.id,
            date: lesson.date,
            status: lesson.status,
            client: {id: lesson.client.id},
            timesheet: {id: context.timesheet.id} as Timesheet,
            favor: {id: lesson?.favor?.id || timesheet?.defaultFavor?.id}
        })

        const convertNote = (note:TimesheetNote) => ({
            id: note.id || undefined,
            note: note.note,
            client: {id: note.client.id},
            timesheet: {id: context.timesheet.id}
        })

        saveTimesheetMutation({
            variables: {
                props: {
                    id: context.timesheet.id,
                    date: moment(context.timesheet.date).format(DATE_FORMAT),
                    lessons: timesheet.lessons.map((lesson) => getEnrichedLesson(lesson)),
                    defaultFavor: {id: timesheet?.defaultFavor?.id},
                    specialist: {id: timesheet.specialist.id },
                    status: timesheet.status === TimesheetStatus.NEW ? TimesheetStatus.ACTIVE : timesheet.status,
                    location: {id: context.timesheet?.location?.id},
                    notes: timesheet.notes.map(convertNote)
                }
            }
        })
            .then(() => markChanged(false))
            .then(() => notification.success({message: "Сохранено"}))
    }

    const sortClientsByAlphabet = (client1:Client, client2:Client):number => {
        if(!client1.fio) return -1
        if(!client2.fio) return 1

        return client1.fio?.localeCompare(client2?.fio) || 0;
    }

    const onNoteChanged = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {value, id = 'client_'} = e.target
        const clientId =  id.substring('client_'.length)
        const {notes} = timesheet

        const targetNoteIndex:number = notes.findIndex((item, index) => item.client.id === clientId)
        const targetClientIndex:number = timesheet.clients.findIndex((client) => client.id === clientId)

        const note:TimesheetNote = targetNoteIndex !== -1
            ? { ...notes[targetNoteIndex], note: value, isDeleted: false }
            : { id: undefined, timesheet: timesheet, client: timesheet.clients[targetClientIndex], note: value }

        const targetNotes:TimesheetNote[] = timesheet.notes.filter((item) => item.client.id !== clientId)
        setTimesheet(prevState => ({
            ...prevState, notes: [ ...targetNotes, note ]
        }))
    }

    const dataSource = [...timesheet.clients.sort(sortClientsByAlphabet), {id: DELETE_LINE_ITEM_ID }]
    const noteColumn:ColumnType<Client> = {
        title: "Примечание",  width: 300, align: "left",
        render: (_, record) => {
            if (record.id === DELETE_LINE_ITEM_ID) return null;
            const value = timesheet.notes.find((item) => item.client.id === record.id)
            return (
                <Input id={`client_${record.id}`}
                       onChange={onNoteChanged}
                       value={value?.note}
                       style={{width: "100%"}} />)
        }
    }

    const isSaveDisabled = () => {
        if (timesheet.status === TimesheetStatus.CLOSED) return true;

        return !timesheet?.lessons?.length || !timesheet?.clients?.length
    }

    return (
        <Spin spinning={context.loading || isTimesheetSaving || isLessonsDeleting}>
            <Row gutter={16}>
                <Col span={24}>
                    <TimesheetHeader
                        lessonsDates={lessonsDates}
                        onDateSelect={addColumn}
                    />
                    <Divider/>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Table columns={[...columns, noteColumn]} dataSource={dataSource}
                                   locale={TABLE_LOCALE} rowKey={"id"}
                                   scroll={{x: 600, y: 600}} pagination={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {
                                isSaveDisabled()
                                    ? <Typography.Text strong={true}  type={"danger"}>Для сохранения выберите клиентов и даты занятий</Typography.Text>
                                    : [
                                        <Button type={"link"} disabled={isSaveDisabled()} onClick={saveTimesheet}>Сохранить</Button>,
                                        <Button type={"link"} disabled={isSaveDisabled()} onClick={saveTimesheetAndSend}>Сохранить и отправить на согласование</Button>
                                    ]
                            }
                            {contextHolder}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Spin>
    )
}