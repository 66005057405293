import React, {useState} from "react";
import {Button, Col, Divider, Input, notification, Row, Spin, Table, Typography} from "antd";
import {useQuery} from "@apollo/client";
import {GET_FAVORS} from "../../components/favors/queries.graphql";
import Favor from "../../model/favor";
import {ColumnsType} from "antd/es/table";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {BATCH_LIST_COLUMNS} from "../batch/list/cols";

interface GetFavorsQueryResult {
    favors: Favor[]
}

export const FavorsListPage = (props:any) => {
    const location = useLocation();
    const [filter, setFilter] = useState("");
    const {loading, data = {favors: []}} = useQuery<GetFavorsQueryResult>(GET_FAVORS, {pollInterval: 5000} )
    const columns:ColumnsType<Favor> = [
        {
            title: "Наименование",
            dataIndex: "name", className:"title"
        },
        {
            title: "Описание",
            className:"description",
            dataIndex: "description"
        },
        {
            title: "Стоимость",
            dataIndex: "price",
            className: "price",
            render: _ => (<span>{Number.parseInt(_)} руб.</span>)
        },
        {
            title: "",
            align: "center",
            width: 250,
            render: (_, record) => ([
                <Link
                    to={`/favors/${record.id}`} state={{backgroundLocation: location}}>
                    <Button type={"link"}>Редактировать</Button>
                </Link>,
            ])

        }
    ]

    const updateFilter = (e:React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value || "");

    const filteredData = (data.favors || [])
        .filter(({name}) => name.toLowerCase().includes(filter.toLowerCase()))

    return (
        <Spin spinning={loading}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Input placeholder={"Введите наименование для поиска"} style={{width: "100%"} } onInput={updateFilter} />
                </Col>
                <Col span={24}>
                    <Table columns={columns} dataSource={filteredData} rowClassName={"favor_item"}
                           footer={() => ([
                               <Link to={`/favors/new`} state={{backgroundLocation: location}}>
                                   <Button className={"add_favor_btn"} type={"link"}>Создать</Button>
                               </Link>
                           ])}
                    />
                </Col>
            </Row>
        </Spin>
    )
}