import React from "react";
import {useParams} from "react-router-dom";
import PageMode, {PageModeRusName} from "../../model/pageMode";
import {useQuery} from "@apollo/client";
import {GET_CLIENT} from "../../pages/client/query.graphql";
import {isEmpty} from "../../utils";
import {ClientQueryResult} from "../../pages/client/edit";

export const EditClientBreadcrumb = (props: any) => {
    const params = useParams<{id: string, mode: PageMode}>()
    const {data} = useQuery<ClientQueryResult>(GET_CLIENT, {
        variables: { id: params.id },
        skip: isEmpty(params.id) || params.mode === PageMode.CREATE
    })

    if (params.mode === PageMode.CREATE) {
        return <span>Создание нового клиента</span>
    }

    const fio = `${data?.client.lastName} ${data?.client.name} ${data?.client.surName || ""}`
    return <span>Редактирование {fio}</span>
}

export const UserProfileBreadcrumb = (props: any) => {
    const {mode} = useParams<{id: string, mode: PageMode}>()
    if (mode === undefined) return null;
    return <span>{PageModeRusName[mode]}</span>
}

export const TimesheetPageBreadcrumb = (props: any) => {
    return (<span>Табель</span>)
}