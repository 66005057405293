import React from "react";
import {Select, Tooltip} from "antd";
import {SelectProps} from "antd/lib/select";
import {RoleCode, RoleRusName} from "../../../model/roles";

const allRolesCodes = [RoleCode.ADMINISTRATOR, RoleCode.SPECIALIST]

export const RoleSelector = (props:SelectProps<any>) => {

    return (
        <Tooltip title={"Роль пользователя задается только при создании. Изменить нельзя"}>
            <Select {...props} >
                {
                    allRolesCodes.map((role) => (
                        <Select.Option value={role}>{RoleRusName[role]}</Select.Option>
                    ))
                }
            </Select>
        </Tooltip>
    )
}

