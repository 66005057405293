import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {NewLocationFormProps} from "./index";
import {NotFoundPage} from "../error";
import {useMutation, useQuery} from "@apollo/client";
import Location from "../../model/location";
import {GET_LOCATION, SAVE_LOCATION} from "./queries.graphql";
import {notification, Spin} from "antd";
import EditLocationForm from "./form";
import {LocationType} from "./utils";

const EditLocationCard = () => {
    const {id} = useParams<NewLocationFormProps>();
    const history = useNavigate();
    const {data = {location: {name: "", id: undefined, address: ""}}, loading, error} = useQuery<{location: Location}>(GET_LOCATION, {
        skip: id === undefined,
        variables: {id},
    })
    const [saveLocation, {loading: savingLocation}] = useMutation(SAVE_LOCATION)

    const onDelete = () => {
        // @ts-ignore
        delete initialValues.__typename;

        saveLocation({
            variables: {
                props: { ...initialValues, isDeleted: true }
            }
        }).then(() => {
            notification.success({message: "Сохранено"});
            history(-1)
        })
    }
    const onSave = ({id, name, address}:any) => {
        saveLocation({
            variables: {props: { id, name, address}}
        }).then(() => {
            notification.success({message: "Saved"});
            history(-1)
        })
    }

    const initialValues = {
        ...data.location,
    }

    return id === undefined ? <NotFoundPage/>
        : <Spin spinning={loading || savingLocation}>
            <EditLocationForm initialValues={initialValues} onSave={onSave} onDelete={onDelete} entityType={LocationType.LOCATION}/>
          </Spin>
}

export default EditLocationCard