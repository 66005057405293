import axios, {AxiosError, AxiosResponse} from "axios";
import {notification} from "antd";

export const deleteTimesheet = async (id: number | string | null): Promise<any> => {
    if (id === null) {
        showErrorNotification("Ошибка при удалении. ID не может быть пустым")
    }

    return axios.post("/rest/timesheet/delete", {id})
        .then((_: AxiosResponse) => notification.success({message: "Сохранено"}))
        .catch((_: AxiosError) => showErrorNotification("При сохранении возникла ошибка. Попробуйте позже."))
}

const showErrorNotification = (message: string) => {
    notification.error({message})
}