import {gql} from "@apollo/client";

export const GET_TIMESHEET_MESSAGES = gql(`query($timesheetId: ID) {
    findAllByTimesheet(timesheetId: $timesheetId) {
        id
        message
        status
        sender { id name lastName surName accountInfo {id iconUrl } }
        auditedInfo { createdDate }
    }
}`)

export const UPDATE_TIMESHEET_MESSAGE_STATUS = gql(`mutation updateTimesheetMessageStatus($props:TimesheetMessageInput){
    updateTimesheetMessageStatus(props: $props) {
        id
    }
}`)

export const SAVE_TIMESHEET_MESSAGE = gql(`mutation saveTimesheetMessage($props:TimesheetMessageInput){
    saveTimesheetMessage(props: $props) {
        id
    }
}`)

