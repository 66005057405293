import React from "react";
import {Select, Spin} from "antd";
import {useQuery} from "@apollo/client";
import Client from "../../../model/client";
import Batch from "../../../model/batch";
import {SelectProps} from "antd/lib/select";
import {GET_CLIENTS_AND_GROUPS} from "../../timesheet/clientSelector/query.graphql";

enum OptionType{
    CLIENT="CLIENT", BATCH="BATCH"
}

interface ClientSelectorProps extends SelectProps{
    placeholder: string
    showBatch?: boolean,
    onSelect: (ids: string[], items: any) => void
    exceptClients: Client[]
    value?: Client[]
    filter?: () => boolean
}

const DEFAULT_PROPS:ClientSelectorProps = {
    placeholder: "",
    exceptClients: [],
    value: [],
    onSelect: () => {},
    showBatch: true
}

const ClientSelector = (props: ClientSelectorProps = DEFAULT_PROPS) => {
    const {loading, data = {clients: []}} = useQuery<{ clients: Client[], batches: Batch[] }>(GET_CLIENTS_AND_GROUPS);

    return (
        <Spin spinning={loading}>
            <Select showSearch mode={"multiple"}
                    onChange={props.onSelect} value={props.value?.map(({id}) => id.toString())}
                    filterOption={true} optionFilterProp={"children"}
                    placeholder={props.placeholder !== undefined ? props.placeholder : "Выберите клиента"}
                    style={{width: "100%"}}>
                <Select.OptGroup label={"Клиенты"}>
                    {
                        data.clients
                            .filter(value => !props.exceptClients.map(_ => _.id).includes(value.id))
                            .map((client) => {
                                const {id, lastName, name, surName} = client;

                                return (
                                    <Select.Option key={`client_${id}`}
                                                   recordId={id}
                                                   type={OptionType.CLIENT}
                                                   title={`${lastName} ${name} ${surName || ""}`}
                                                   value={id}
                                    >
                                        {lastName} {name} {surName || ""}
                                    </Select.Option>
                                )
                            })
                    }
                </Select.OptGroup>
            </Select>
        </Spin>
    )
};

export default ClientSelector