import React from "react";
import EntityValidationStatus from "../../model/validationStatus";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined
} from "@ant-design/icons";
import {Tag} from "antd";

export interface EntityValidationStatusTagProps {
    status: EntityValidationStatus
}

const EntityValidationStatusTag = ({status:{name, rusName}}:EntityValidationStatusTagProps) => {
    switch (name) {
        case "NEED_VALIDATE": return (<Tag icon={<ExclamationCircleOutlined />} color="warning">{rusName}</Tag>);
        case "VALIDATING": return (<Tag icon={<SyncOutlined spin />} color="processing">{rusName}</Tag>);
        case "VALIDATED": return (<Tag icon={<CheckCircleOutlined />} color="success">{rusName}</Tag>);
        case "REJECTED": return (<Tag icon={<CloseCircleOutlined />} color="error">{rusName}</Tag>);

        default: return (<Tag icon={<MinusCircleOutlined />} color="default">Нет данных</Tag>);
    }
}

export default EntityValidationStatusTag