import {gql} from "@apollo/client";

export const GET_BATHES = gql(`query {
    batches {
        id
        name
        description
        location {id name }
    }
}`)

const GET_BATCHES_FOR_LOCATION = gql(`query batchesForLocation($locationId: ID) {
    batchesForLocation(locationId: $locationId) {
        id
        name
        isPrimary
    }
}`)

export const SAVE_CLIENT = gql(`mutation saveClient($client: ClientInput) {
    saveClient(client: $client) {
        id
    }
}`)