import {Col, Row} from "antd";
import React from "react";
import {TimesheetInfoPanel} from "./infoPanel";
import {TimesheetWorkPanel} from "./workPanel";

const TimesheetHeader = ({lessonsDates, onDateSelect}:any) => {
    return (
        <Row gutter={[16,16]}>
            <Col span={12}><TimesheetWorkPanel {...{onDateSelect, lessonsDates}} /></Col>
            <Col span={12}><TimesheetInfoPanel/></Col>
        </Row>
    )
}

export default TimesheetHeader