import React from "react";
import {useTimesheetContext} from "../context";
import {List, Typography} from "antd";

export const TimesheetErrors = () => {
    const {errors, warnings } = useTimesheetContext()
    const errorMessages = errors
        .map(message => (<Typography.Text type={"danger"} style={{paddingRight: 5}}>{message};</Typography.Text>))

    const warningMessages = warnings
        .map(message => (<Typography.Text type={"warning"} style={{paddingRight: 5}}>{message};</Typography.Text>))

    return (
        <>
            <List size={"small"} split={false}>
                {
                    [...errorMessages, ...warningMessages]
                        .map((item, index) => (<List.Item key={`error_${index}`}>{item}</List.Item>))
                }
            </List>
        </>
    )
};
