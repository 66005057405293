import {gql} from "@apollo/client";

export const GET_PERSON_INFO = gql(`query person($id: ID) {
    person(id: $id) {
        id
        name
        lastName
        surName
        description
        phoneNumber
        personType
        accountInfo { id login iconUrl canAuth roles owner {id} }
    }
}`)

export const SAVE_PERSON = gql(`mutation saveEmployee($employee: PersonInfoInput) {
    saveEmployee(employee: $employee) {
        id
    }
}`);

export const GET_RESTRICTIONS = gql(`query restrictions($specialistId: ID) {
    restrictions(specialistId: $specialistId) {
        id
        restrictionType { description name }
        auditedEntityInfo { modifiedDate createdDate modifiedBy createdBy  }
    }
}`);

export const DELETE_RESTRICTIONS = gql(`mutation deleteRestriction($id: ID) {
    deleteRestriction(id: $id) }`);
