import React from "react";
import {DatePicker} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment, {Moment} from "moment";
import {PickerProps} from "antd/lib/date-picker/generatePicker";

const DEFAULT_PROPS:PickerProps<Moment> = {
    format: "DD.MMMM.YYYY",
    locale: locale,
    style: {width: "100%"}
}

const DefaultDatePicker = (customProps: PickerProps<Moment>) => {

    return <DatePicker {...DEFAULT_PROPS} {...customProps}/>
}

export default DefaultDatePicker