import {gql} from "@apollo/client";

export const GET_TIMESHEET_LIMITS = gql(`query limits($timesheetId: ID){
    timesheetLimits(timesheetId: $timesheetId) {
        id
        person { id name lastName birthday }
        auditedEntityInfo { createdDate createdBy }
    }
}`)

export const DELETE_LIMIT = gql(`mutation deleteLimit($limit: TimesheetLimitInput){
    deleteLimit(limit: $limit) {
        id
    }
}`)

export const SAVE_LIMIT = gql(`mutation saveLimits($limits: [TimesheetLimitInput]){
    saveLimits(limits: $limits) {
        id
    }
}`)

