import React, {useState} from "react";
import moment from "moment";
import {useTimesheetContext} from "../context";
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_LIMIT} from "./query.graphql";
import {isEmpty} from "../../../utils";
import {Button, notification, Popconfirm, Spin, Table} from "antd";
import {NoDataText} from "../../../components/utils";
import {Link, useLocation} from "react-router-dom";

const TimesheetLimitsTab = () => {
    const {limits, timesheet, loading, updateLimits} = useTimesheetContext()
    const [updateLimit, {loading:saving}] = useMutation(DELETE_LIMIT)
    const location = useLocation();

    const deleteLimit = (record: any) => {
        updateLimit({
            variables: {
                limit: {
                    id: record.id,
                    isDeleted: true
                }
            }
        })
            .then(() => notification.success({message:"Сохранено"}))
            .then(() => updateLimits())
    }

    const cols = [
        {
            title: "ФИО",
            dataIndex: "person",
            render: (item:any) => (<span>{item.lastName} {item.name}</span>)
        },
        {
            title: "Дата рождения",
            dataIndex: "person",
            render: (item:any) => isEmpty(item.birthday)
                ? <NoDataText />
                : <span>{moment(item.birthday).format("DD.MM.YYYY")}</span>
        },
        {
            render: (_:any, record:any) => ([
                <Popconfirm title={"Вы уверены?"} okText={"Да"} onConfirm={() => deleteLimit(record)}>
                    <Button type={"link"}>Удалить блокировку</Button>
                </Popconfirm>
            ])
        }

    ];
    return (
        <Spin spinning={loading || saving}>
            <Table rowKey={"id"} dataSource={limits} columns={cols}
                   footer={() =>(
                       <Link to={`/tabel/${timesheet.id}/limit/add`} state={{backgroundLocation: location}}>
                           <Button type={"link"}>Добавить</Button>
                       </Link>
                   )}
            />
        </Spin>
    )
}

export {TimesheetLimitsTab}