import React, {useCallback} from "react";
import Client from "../../../../model/client";
import {DELETE_LINE_ITEM_ID} from "../../utils";
import DeleteButton from "../../../../components/buttons/delete";


export interface DeleteClientButtonProps {
    client: Client
    onDelete: (client: Client) => void;
}
const DeleteClientButton = ({client, onDelete}:DeleteClientButtonProps) => {
    const onConfirm = useCallback(() => onDelete(client), [client, onDelete])

    if (client.id !== DELETE_LINE_ITEM_ID) {
        return (<DeleteButton type={"icon"} onConfirm={onConfirm} />)
    }

    return null;
}

export default DeleteClientButton;
