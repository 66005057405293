
export enum TimesheetStatus {
    NEW="NEW",
    ACTIVE="ACTIVE",
    ON_APPROVAL="ON_APPROVAL",
    CLOSED="CLOSED"
}

export enum TimesheetStatusRusName {
    NEW="Новый",
    ACTIVE="Активный",
    ON_APPROVAL="Согласование",
    CLOSED="Закрыт"
}

export const TimesheetStatuses = [
    TimesheetStatus.NEW,
    TimesheetStatus.ACTIVE,
    TimesheetStatus.ON_APPROVAL,
    TimesheetStatus.CLOSED,
]