import React from "react";
import {Card, Col, Row} from "antd";
import PageMode from "../../model/pageMode";
import {useParams} from "react-router-dom";
import {NotFoundPage} from "../error";
import EditLocationCard from "./edit";
import {LocationType} from "./utils";
import CreateLocationForm from "./new";
import {BatchesPage} from "../batch";

export interface NewLocationFormProps extends Record<string, any>{
    id: string | undefined
    mode: PageMode
    entityType: LocationType
}

const EDIT_FORM_TITLE = "Редактирование локации"
const CREATE_FORM_TITLE = "Создание локации"

const ModifyLocationPage = () => {
    const {mode, id} = useParams<NewLocationFormProps>();
    if (mode === PageMode.EDIT && id === undefined) {
        return (<NotFoundPage/>)
    }

    return (
        <Row gutter={[16, 16]} >
            <Col span={isEditMode(mode) ? 12 : 24}>
                <Card title={isEditMode(mode) ? EDIT_FORM_TITLE : CREATE_FORM_TITLE}>
                    {
                        isEditMode(mode) ? <EditLocationCard /> : <CreateLocationForm />
                    }
                </Card>
            </Col>
            <Col span={12}>
                <Card hidden={!isEditMode(mode)} title={"Группы"}>
                    <BatchesPage locationId={id}/>
                </Card>
            </Col>
        </Row>
    )
}

const isEditMode = (mode: PageMode | undefined) => mode === PageMode.EDIT

export default ModifyLocationPage;