import {useTimesheetContext} from "./context";
import {useAuth} from "../../auth/authContext";
import {useQuery} from "@apollo/client";
import {GET_UNREADED_MESSAGES} from "./query.graphql";
import {Badge, Tabs, TabsProps} from "antd";
import {TimesheetTab} from "./timesheet";
import {CorrespondenceTab} from "./correspondence";
import React from "react";
import {TimesheetLimitsTab} from "./limits/timesheetTab";
import TimesheetClientsTab from "./batches";
import {TimesheetClientsContextProvider} from "./batches/context";
import {isEmpty} from "../../utils";

const ClientsTab = () => {
    const {timesheet} = useTimesheetContext();

    return (
        <TimesheetClientsContextProvider value={{location: timesheet?.location?.id}}>
            <TimesheetClientsTab />
        </TimesheetClientsContextProvider>
    )
}

const CorrespondenceTabTitle = () => {
    const {timesheet} = useTimesheetContext();
    const {userInfo} = useAuth();

    const {refetch, data:{unreadedMessages} = {unreadedMessages: []}} = useQuery(GET_UNREADED_MESSAGES, {
        skip: isEmpty(timesheet?.id) || isEmpty(userInfo?.id),
        variables: {
            timesheetId: timesheet.id,
            personId: userInfo.id
        }
    })

    return (
        <Badge count={unreadedMessages.length} size={"small"} overflowCount={10}>
            <span>Переписка</span>
        </Badge>)
}

const tabsListItems: TabsProps['items'] = [
    {
        key: "timesheet",
        label: "Табель",
        children: <TimesheetTab key={`timesheet`} />
    },
    {
        key: "clients",
        label: "Клиенты",
        children: <ClientsTab key={`clients`} />
    },
    {
        key: "correspondence",
        label: <CorrespondenceTabTitle key={`correspondence_label`} />,
        children: <CorrespondenceTab key={`correspondence`} />
    },
];

const limitsTab = {
    key: "limits",
    label: "Ограничения",
    children: <TimesheetLimitsTab key={`limits`}/>
}

export const MenuTabs = () => {
    const {isAdministrator} = useAuth();
    const items = [...tabsListItems]

    if (isAdministrator()) {
        items.push(limitsTab)
    }

    return (
        <Tabs defaultActiveKey="timesheet" key={'tabs'}
              items={items}
        />
    )
}
