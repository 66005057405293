import {gql} from "@apollo/client";

const GET_CLIENTS_FOR_TIMESHEET = gql(`query clientsForTimesheet($timesheet: ID) {
    clientsForTimesheet(timesheet: $timesheet) {
        id
        fio
        birthday
        batch { id name } 
    }
}`)

const GET_BATCHES_FOR_LOCATION = gql(`query batchesForLocation($locationId: ID) {
    batchesForLocation(locationId: $locationId) {
        id
        name
        isPrimary
    }
}`)

const UPDATE_CLIENT_BATCHES = gql(`mutation updateClientBatches($client: ClientInput) {
    updateClientBatches(client: $client) {
        id
    }
}`);


export {UPDATE_CLIENT_BATCHES, GET_CLIENTS_FOR_TIMESHEET, GET_BATCHES_FOR_LOCATION}
