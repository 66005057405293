import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {GetBatchesResponseProps} from "../../pages/batch/list/listOfBatch";
import {GET_BATCHES_FOR_LOCATION, SAVE_BATCH} from "../../pages/batch/queries.graphql";
import {isEmpty} from "../../utils";
import {Button, Divider, Empty, Input, InputRef, notification, Select, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import Batch from "../../model/batch";
import {Link} from "react-router-dom";
import {BatchesSelectorProps} from "./batchesSelectorProps";
import {DEFAULT_PROPS} from "./utils";

export const SelectorForLocation = (customProps: BatchesSelectorProps) => {
    const [open, setOpen] = useState(false);
    const {locationId, showPrimary} = customProps
    const {loading, refetch,data:{batchesForLocation} = {batchesForLocation: []}}
        = useQuery<GetBatchesResponseProps>(GET_BATCHES_FOR_LOCATION,
        {variables: { locationId: locationId }, skip: isEmpty(locationId)})

    return (
        <Select {...DEFAULT_PROPS} {...customProps} open={open}
                dropdownRender={(menu) => (<NewBatchField {...{closeSelector: () => setOpen(false), locationId, updateBatches: refetch}}>{menu}</NewBatchField>)}
                loading={loading} notFoundContent={notFoundContent(locationId)}
                onDropdownVisibleChange={(visible) => setOpen(visible)}
        >
            {
                batchesForLocation
                    .filter((batch: Batch) => showPrimary? true : !batch.isPrimary)
                    .map((batch: Batch) => (
                        <Select.Option disabled={batch.isPrimary} key={`${batch.id}`}>{batch.name}</Select.Option>
                    ))
            }
        </Select>
    )
}

const NewBatchField = ({children, locationId, updateBatches, closeSelector}: any) => {
    const [newBatchName, setNewBatch] = useState<string|undefined>("");
    const [saveBatch] = useMutation(SAVE_BATCH)
    const addItem = () => {
        saveBatch({
            variables: {
                props: {
                    name: newBatchName,
                    location: {id: locationId}
                }
            }
        })
            .then(() => setNewBatch(undefined))
            .then(() => notification.success({message: "Сохранено"}))
            .then(closeSelector)
            .then(() => updateBatches())
            .catch((error:Error) => notification.error({message: "При сохранении возникла ошибка: ", description: error.message}))
    };
    return (
        <div style={{ width: "100%" }}>
            {children}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 4px 8px' }} align={"end"} direction={"horizontal"}>
                <Input style={{ width: "350px" }}
                       placeholder="Введите название новой группы"
                       value={newBatchName}
                       onChange={({target: {value}}) => setNewBatch(value)}

                />
                <Button type="text" icon={<PlusOutlined />} disabled={newBatchName === null || newBatchName === undefined || newBatchName.length < 3} onClick={() => addItem()}>
                    Создать
                </Button>
            </Space>
        </div>
    )
}

const notFoundContent = (locationId: number|string|undefined) => {
    return (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
               children={(<Link to={{pathname: `/clients/create`}} state={{location: {id: locationId}}}>
                   <Button type={"link"}>Создать карточку нового клиента</Button></Link>)}
        />
    )
}