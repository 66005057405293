import {Button, PageHeader} from "antd";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useBreadcrumbs, {BreadcrumbData} from 'use-react-router-breadcrumbs';
import Breadcrumb from "antd/lib/breadcrumb/Breadcrumb";
import {AppBreadcrumbsRoute, backgroundRoutes, protectedRoutes} from "../../auth/routes";

export const AppPage = ({children}: any) => {
    const navigate = useNavigate();
    const location = useLocation()
    const breadcrumbs = useBreadcrumbs(protectedRoutes, {
        disableDefaults: true,
        excludePaths: backgroundRoutes.map(({path}: AppBreadcrumbsRoute) => path || '')
    });

    const breadcrumbsItems = breadcrumbs
        .map(({match, breadcrumb}) => (
            <Breadcrumb.Item key={match.pathname}>
                <Button type={"link"} onClick={() => navigate(match.pathname)}
                        style={{padding: 0}}>{breadcrumb}</Button>
            </Breadcrumb.Item>
        ));

    return (
        <PageHeader
            ghost={false}
            onBack={location.pathname === "/" ? undefined : () => navigate(-1)}
            title={<PageTitle breadcrumbs={breadcrumbs}/>}
            breadcrumb={<Breadcrumb>{breadcrumbsItems}</Breadcrumb>}>
            {children}
        </PageHeader>
    )
}

const PageTitle = ({breadcrumbs}: { breadcrumbs: BreadcrumbData[] }) => {
    const breadcrumb = breadcrumbs[breadcrumbs.length - 1];
    // @ts-ignore
    if (breadcrumb?.breadcrumb?.props?.children) {
        // @ts-ignore
        return (<span>{breadcrumb?.breadcrumb?.props?.children}</span>)
    }

    return null;
}