import React from "react";
import UserHeader from "../userHeader";
import {Button, Col, Layout, Row} from "antd";
import "./index.css";
import {useAuth} from "../../auth/authContext";
import LoginPage from "../../pages/login";
import {getAccessToken} from "../../auth/tokenUtils";
import {getApolloClient} from "../../auth/apolloclient";
import { ApolloProvider } from "@apollo/react-hooks";
import {RoleCode} from "../../model/roles";
import {NotFoundPage} from "../../pages/error";
import {AppPage} from "../pageHeader";
import {BrowserRouter, Navigate, Route, useNavigate, useLocation, Routes} from "react-router-dom";
import {AppBreadcrumbsRoute, backgroundRoutes, protectedRoutes} from "../../auth/routes";
import SpecialistRegistrationPage from "../../pages/specialists/registration";

const {Header, Content} = Layout;

const AppContent = ({children}: any) => {
    const {isAuthenticated} = useAuth()
    return (
        <Layout className="site-layout">
            {isAuthenticated ? (<AppHeader/>) : null}
            <AppPage>
                <Content className="site-layout-background">
                    {children}
                </Content>
            </AppPage>
        </Layout>
    )
}

const AppHeader = () => {
    return (
        <Header className="site-layout-background" style={{paddingLeft: "10px", height: "80px"}}>
            <Row gutter={[16, 16]} align={"middle"}>
                <Col>
                    <UserHeader/>
                </Col>
                <Col>
                    <Navigation />
                </Col>
            </Row>
        </Header>
    )
}

const BaseLayout = () => {
    return (
        <ApolloProvider client={getApolloClient()}>
            <BrowserRouter>
                <AppRoutes/>
            </BrowserRouter>
        </ApolloProvider>
    )
}

const AppRoutes = () => {
    const location = useLocation();
    const state = location.state as { backgroundLocation?: Location };
    return (
        <div>
            <Routes location={state?.backgroundLocation || location}>
                <Route path={"/"} >
                    <Route path={"/login"} element={<LoginPage/>} />
                    <Route path={"/specialists/registration"} element={<SpecialistRegistrationPage />} />
                    {
                        protectedRoutes
                            // @ts-ignore
                            .map(({path, element}:AppBreadcrumbsRoute, index) => (
                                <Route key={`route_${index}`} path={path} element={(<ProtectedRoute>{element}</ProtectedRoute>)}/>))
                    }
                    <Route path="*" element={<NotFoundPage/>}/>
                </Route>
            </Routes>
            {state?.backgroundLocation && (
                <Routes>
                    {
                        backgroundRoutes.map(({path, element}:AppBreadcrumbsRoute) => (<Route path={path} element={element}/>))
                    }
                </Routes>
            )}
        </div>
    )
}

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    let auth = getAccessToken()
    let location = useLocation();

    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return (<AppContent>{children}</AppContent>);
}

const Navigation = () => {
    const navigate = useNavigate();
    const {userInfo} = useAuth()

    const {logout} = useAuth()
    return (
        <Row justify={"space-between"}>
            {
                !userInfo?.roles?.map(role => role.code).includes(RoleCode.ADMINISTRATOR) ? null : (
                    <Col>
                        <Row>
                            {[
                                <Button className={"action_btn"}  key={"nav_btn_tabels"} type={"link"} onClick={() => navigate("/admin/tabels")}>Табеля</Button>,
                                <Button className={"action_btn"} key={"nav_btn_clients"} type={"link"} onClick={() => navigate("/clients")} >Клиенты</Button>,
                                <Button className={"action_btn"} key={"nav_btn_users"} type={"link"} onClick={() => navigate("/admin/users")} >Сотрудники</Button>,
                                <Button className={"action_btn"} key={"nav_btn_locations"} type={"link"} onClick={() => navigate("/admin/locations")} >Локации</Button>,
                                <Button className={"action_btn"} key={"nav_btn_favors"} type={"link"} onClick={() => navigate("/favors")} >Услуги</Button>,
                            ].map((btn1:any, index) => (<Col key={`col_${index}`}>{btn1}</Col>))}
                        </Row>
                    </Col>
                )
            }
            <Col>
                <Button className={"action_btn"} style={{alignContent:"end"}} type={"link"} danger onClick={logout}>
                    Выйти
                </Button>
            </Col>
        </Row>
    )
}

export default BaseLayout;