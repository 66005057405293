import React from "react";
import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

export interface ErrorResultProps {
    errors: any,
    onBack?: () => {} | undefined
}

export const ErrorResult = ({errors, onBack}:ErrorResultProps) => {
    const navigate = useNavigate();

    const goBack = () => {
        if (onBack) onBack();
        else navigate(-1)
    }

    console.error(errors);
    return (
        <Result
            status="warning"
            title="Произошла ошибка. Попробуйте повторить позже"
            extra={
                <Button type="primary" key="console" onClick={goBack}>
                    Вернуться назад
                </Button>
            }
        />
    )
}