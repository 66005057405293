import React from "react";
import {Button, Col, Input, InputRef, message, notification, Row, Spin, Table} from "antd";
import {useQuery} from "@apollo/client";
import Client from "../../../model/client";
import Batch from "../../../model/batch";
import {GET_CLIENTS_FOR_TIMESHEET} from "./query.graphql";
import {useTimesheetContext} from "../context";
import {ColumnType} from "antd/es/table";
import {NoDataText} from "../../../components/utils";
import moment from "moment";
import LocationBatchesSelector from "./selector";
import {useTimesheetBatchesContext} from "./context";
import AddBatchForTimesheetModal from "./addTimesheetBatchModal";
import {EditOutlined} from "@ant-design/icons";
import {Link, useLocation} from "react-router-dom";

const TimesheetBatchesTab = () => {
    const {
        location: timesheetLocation,
        fioFilter, setFioFilter,
        updateBatches,
        setCreateBatchModalProps,
        batchesForLocation, refetchBatches
    } = useTimesheetBatchesContext()

    const sss = useTimesheetBatchesContext();
    const {timesheet} = useTimesheetContext()
    const location = useLocation();
    const {
        loading, refetch,
        data = {clients: [],clientsForTimesheet: [], batchesForTimesheet: []}
    } = useQuery<{ clients: Client[], clientsForTimesheet: Client[], batchesForTimesheet: Batch[] }>(GET_CLIENTS_FOR_TIMESHEET, {
        variables: {
            timesheet: timesheet.id
        }, skip: timesheet?.id == undefined
    })


    const updateFilter = (e:React.ChangeEvent<HTMLInputElement>) => setFioFilter(e.target.value || "");
    console.info('useTimesheetBatchesContext', sss)
    const cols:ColumnType<Client>[] = [
        {
            dataIndex: "fio", title: "ФИО",
        },
        {
            dataIndex: "birthday",
            title: "Дата рождения",
            render: (field: any) => field === null ? (<NoDataText/>) : moment(field).format("DD.MM.yyyy")
        },
        {
            title: "Группы", width: "50%",
            render: (_:any, record: Client) => (
                <LocationBatchesSelector value={record.batch.map((item=>item.id))}
                                         onChange={(items: any[]) => updateBatches(record, items).then(refetch)}
                                         batches={batchesForLocation}
                                         locationId={timesheet?.location?.id}
                />
            )

        },
        {
            render: (_:any, record: Client) => (
                (<Link to={`/clients/edit/${record.id}`} state={{
                    backgroundLocation: location,
                    location: timesheetLocation?.id
                }}>
                    <Button type={"link"}>Редактировать</Button>
                </Link>)
            )
        }
    ]

    const addBatchBtn = () => (
        <Button type={"link"} onClick={() => setCreateBatchModalProps({open: true})}>
            Добавить группу
        </Button>
    )

    return (
            <Spin spinning={loading} style={{width: "100%"}}>
                <AddBatchForTimesheetModal locationId={timesheet.location?.id || ""}
                                           onAfterFinish={() => {
                                               message.success({content: "Сохранено"})
                                               setCreateBatchModalProps({open: false});
                                               refetchBatches();
                                           }}
                />
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Input placeholder={"Поиск по ФИО. Например: Иванов Иван Иванович"} onChange={updateFilter}  />
                    </Col>
                    <Col span={24}>
                        <Table dataSource={data.clientsForTimesheet.filter(value => fioPredicate(value, fioFilter))}
                               columns={cols}
                               footer={addBatchBtn}
                        />
                    </Col>
                </Row>
            </Spin>
    )
}

const fioPredicate = (item: Client, filter:string | null) => {
    if (filter === null) return true;

    return (`${item.id} ${item?.fio}` || "").toUpperCase().includes(filter.toUpperCase())
}

TimesheetBatchesTab.whyDidYouRender = false;
export default TimesheetBatchesTab;