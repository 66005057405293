import {gql} from "@apollo/client";

export const GET_EMPLOYEES = gql(`query employees {
    employees {
        id
        name
        lastName
        surName
        accountInfo { roles }
    }
}`)