import React from "react";
import {Select, Spin} from "antd";
import {SelectProps} from "antd/es";
import Batch from "../../../model/batch";
import {useTimesheetBatchesContext} from "./context";

interface LocationBatchesSelectorProps extends SelectProps{
    locationId: string | number | undefined
    batches: Batch[]
}

const LocationBatchesSelector = (props: LocationBatchesSelectorProps) => {
    const {
        loadingBatches:loading
    } = useTimesheetBatchesContext()

    return (
        <Select {...props} loading={loading} style={{width: "100%"}} mode={"multiple"}>
            {
                props.batches.map((item:Batch) => (
                    <Select.Option key={item.id} disabled={item.isPrimary}>{item.name}</Select.Option>
                ))
            }
        </Select>
    )
}

export default LocationBatchesSelector