import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

export interface NotFoundProps {
    message?: string
}

const NotFoundPage = ({message}:NotFoundProps) => {
    const navigate = useNavigate();
    return (
        <Result
            status="404"
            title="404"
            subTitle={message || "Страницы не существует"}
            extra={<Button type="primary" onClick={() => navigate(-1)}>Вернуться назад</Button>}
        />
    )
}

export {NotFoundPage}