import MainPage from "../pages/main";
import {BreadcrumbsRoute} from "use-react-router-breadcrumbs";

import TimeTable from "../pages/timetable";
import TimesheetsPage from "../pages/timesheets";
import TimesheetPage from "../pages/timesheet";
import EditClientModal from "../pages/client/edit";
import {EditFavorModal, FavorsPage} from "../pages/favors";
import {BatchDetailPage} from "../pages/batch";
import {UserProfilePage} from "../pages/user/profile";
import {SpecialistsPage} from "../pages/specialists";
import AdminTimesheetsPage from "../pages/timesheets/admin/page";
import {EditSpecialistPage} from "../pages/specialists/admin/specialist";
import {LocationsPage} from "../pages/locations";
import ModifyLocationPage from "../pages/location";
import React from "react";
import {TimesheetPageBreadcrumb, UserProfileBreadcrumb} from "../components/breadcrumbs";
import TimeSheetPropsModal from "../pages/timesheets/create";
import {ClientsPage} from "../pages/clients";
import {AddLimitModal} from "../pages/timesheet/limits/addLimit";
import EditRestrictionModal from "../components/restrictions/modal";


export interface AppBreadcrumbsRoute extends BreadcrumbsRoute {
    path: string
    element: React.ReactElement
}

export const protectedRoutes:AppBreadcrumbsRoute[] = [
    {path: "/", element: <MainPage />, breadcrumb:"Главная страница", props: { title: "Главная страница"}},
    {path: "/clients", element: <ClientsPage/>, breadcrumb:"Список клиентов", props: { title: "Клиенты"}},
    {path: "/timetable", element: <TimeTable/>,breadcrumb:"Табеля",},
    {path: "/tabels", element: <TimesheetsPage/>,breadcrumb:"Табеля",},
    {path: "/tabel/:id",  element: <TimesheetPage />, breadcrumb: TimesheetPageBreadcrumb, props: { title: "Табель"}},
    {path: "/favors", element: <FavorsPage />, breadcrumb:"Услуги",},
    {path: "/user/profile/:id/:mode",  element: <UserProfilePage/>, breadcrumb: UserProfileBreadcrumb},
    {path: "/user/profile/:id",  element: <UserProfilePage/>, breadcrumb: null},
    {path: "/user/profile",  element: <UserProfilePage/>, breadcrumb: "Профиль пользователя"},
    {path: "/admin/users", element: <SpecialistsPage/>,breadcrumb:"Сотрудники",},
    {path: "/admin/tabels", element: <AdminTimesheetsPage/>, breadcrumb:"Табеля",},
    {path: "/admin/specialist/:id/tabels", element: <TimesheetsPage/>, breadcrumb: undefined},
    {path: "/admin/specialist/edit", element: (<EditSpecialistPage/>), breadcrumb:"Редактирование специалиста",},
    {path: "/admin/locations", element: (<LocationsPage/>),breadcrumb:"Локации",},
    {path: "/admin/location/:mode/:id", element: <ModifyLocationPage/>, breadcrumb: undefined},
    {path: "/admin/location/:mode/", element: <ModifyLocationPage/>, breadcrumb: undefined},
]

export const backgroundRoutes: AppBreadcrumbsRoute[] = [
    {path: "/favors/:id", element: <EditFavorModal/> , breadcrumb: undefined, props: {modal: true}},
    {path: "/tabels/:mode/:source", element: <TimeSheetPropsModal/> , breadcrumb: undefined, props: {modal: true}},
    {path: "/tabels/:mode", element: <TimeSheetPropsModal/> , breadcrumb: undefined, props: {modal: true}},
    {path: "/batches/new", element: <BatchDetailPage/> , breadcrumb: undefined, props: {modal: true}},
    {path: "/batches/edit/:id", element: <BatchDetailPage/> , breadcrumb: undefined, props: {modal: true}},
    {path: "/clients/:mode", element: <EditClientModal />},
    {path: "/clients/:mode/:id", element: <EditClientModal />},
    {path: "/tabel/:timesheetId/limit/add", element: <AddLimitModal />},
    {path: "/user/profile/:userId/restriction", element: <EditRestrictionModal />},
    {path: "/user/profile/:userId/restriction/:resctictionId", element: <EditRestrictionModal />},
]