import React from "react";
import {isEmpty} from "../../utils";
import {BatchesSelectorProps} from "./batchesSelectorProps";
import {SelectorForLocation} from "./selectorByLocation";
import {AllBatchSelector} from "./fullSelector";


export const BatchSelector = (customProps: BatchesSelectorProps) => {
    return isEmpty(customProps?.locationId)
        ? (<AllBatchSelector {...customProps} />)
        : (<SelectorForLocation {...customProps} />)
}
