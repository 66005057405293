import {Collapse, Descriptions} from "antd";
import {useTimesheetContext} from "../context";
import DescriptionsItem from "antd/es/descriptions/Item";
import React from "react";
import {NoDataText} from "../../../components/utils";
import {TimesheetStatus, TimesheetStatusRusName} from "../../../model/timesheetStatus";

export const TimesheetInfoPanel = () => {
    return (
        <Collapse defaultActiveKey={1} activeKey={1}>
            <Collapse.Panel header="Информация о табеле" key="1">
                <TimesheetDescription />
            </Collapse.Panel>
        </Collapse>
    )
};

const MONTH_INFO_FORMAT_PATTERN = "MMMM YYYY"

const TimesheetDescription = () => {
    const {date, favor, specialist, location, status} = useTimesheetContext()
    const specialistFio = `${specialist?.lastName} ${specialist?.name} ${specialist?.surName}`
    const timesheetStatusRusName = TimesheetStatusRusName[status as keyof typeof TimesheetStatus]

    return (
        <Descriptions column={2} style={{width: "100%"}}>
            <DescriptionsItem label={"Месяц"}>{date.format(MONTH_INFO_FORMAT_PATTERN).toUpperCase()}</DescriptionsItem>
            <DescriptionsItem label={"Услуга"}>{favor?.name || <NoDataText/>}</DescriptionsItem>
            <DescriptionsItem label={"Специалист"}>{specialistFio}</DescriptionsItem>
            <DescriptionsItem label={"Место проведения занятий"}>{location?.name}</DescriptionsItem>
            <DescriptionsItem label={"Статус табеля"}>{timesheetStatusRusName}</DescriptionsItem>
        </Descriptions>
    )
}