import React, {useContext, useState} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import {GET_BATCHES_FOR_LOCATION, UPDATE_CLIENT_BATCHES} from "./query.graphql";
import Client from "../../../model/client";
import {notification} from "antd";
import {GetBatchesResponseProps} from "../../batch/list/listOfBatch";
import {isEmpty} from "../../../utils";
import {useTimesheetContext} from "../context";

type TimesheetBatchesContextData = ReturnType<typeof useProvideContext>;

const TimesheetBatchesContext = React.createContext<TimesheetBatchesContextData>({} as TimesheetBatchesContextData);

export const useTimesheetBatchesContext = () => {
    return useContext(TimesheetBatchesContext);
};

export const TimesheetClientsContextProvider = ({ children }:any) => {
    const context = useProvideContext();
    return <TimesheetBatchesContext.Provider value={context}>{children}</TimesheetBatchesContext.Provider>;
};

const useProvideContext = () => {
    const {location} = useTimesheetContext()
    const [fioFilter, setFioFilter] = useState<string|null>(null)
    const [createBatchModalProps, setCreateBatchModalProps] = useState<any>({open: false})
    const [updateBatchesMutation, {loading: saving}] = useMutation(UPDATE_CLIENT_BATCHES)
    const [refetchBatchesSelectors, setBatchesRefetchFunction] = useState<(() => Partial<any>) | undefined>(undefined)

    const updateBatches = (client: Client, batchesIds: number[]):Promise<any> => {
        return updateBatchesMutation({
            variables: {
                client: {
                    id: client.id,
                    batch: batchesIds.map(id => ({id:id}))
                }
            }
        })
            .then(() => {
                notification.success({message: "Сохранено"});
            })
            .catch(() => notification.error({ message: "При сохранении возникла ошибка. Попробуйте позже"}))
    }

    const {
        loading:loadingBatches, refetch: refetchBatches,
        data:{batchesForLocation} = {batchesForLocation: []}
    } = useQuery<GetBatchesResponseProps>(GET_BATCHES_FOR_LOCATION,
        {
            variables: { locationId: location?.id },
            skip: isEmpty(location?.id)
        }
    )

    return {
        location,
        batchesForLocation, refetchBatches, loadingBatches,
        fioFilter, setFioFilter,
        updateBatches,
        refetchBatchesSelectors, setBatchesRefetchFunction,
        createBatchModalProps, setCreateBatchModalProps
    };
};

export default TimesheetBatchesContext