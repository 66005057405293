import React from "react";
import {Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {LessonStatus} from "../../../../model/lessonStatus";
import Lesson from "../../../../model/lesson";
import Client from "../../../../model/client";
import {useTimesheetContext} from "../../context";
import {Moment} from "moment";
import Timesheet from "../../../../model/timesheet";
import {DATE_FORMAT} from "../../utils";

export interface LessonCheckboxProps {
    date: Moment
    timesheet: Timesheet
    onChange: (status: boolean, client: Client, lesson?:Lesson) => void
    client: Client
}

const LessonCheckbox = ({date, timesheet, client, onChange}:LessonCheckboxProps) => {
    const context = useTimesheetContext()

    const lesson = timesheet.lessons.find(lesson => lesson.client.id === client.id
        && (lesson.date === date.format(DATE_FORMAT)))

    const disabled = context.limits
        .map((limit:any) => limit.person)
        .findIndex((value:any) => value.id === client.id) !== -1

    const onCheckboxChange = ({target: {checked}}:CheckboxChangeEvent) => onChange(checked, client, lesson)
    const checked = lesson?.status === LessonStatus.COMPLETED

    if (lesson === undefined) {
        return null;
    }

    return (
        <Checkbox disabled={disabled}
                  onChange={onCheckboxChange}
                  checked={checked}
        />
    )
}

export default LessonCheckbox;