import React from "react";
import {useQuery} from "@apollo/client";
import {GET_SPECIALISTS} from "./queries.graphql";
import Specialist from "../../../../model/specialist";
import {Select} from "antd";
import {SelectProps} from "antd/lib/select";

const DEFAULT_PROPS:SelectProps<any> = {
    placeholder: "Выберите услугу",
    showSearch: true,
    optionFilterProp: "children",
    style: {width: "100%"}
}

const SpecialistSelector = (customProps:SelectProps<any>) => {
    const {loading, data:{specialists} ={specialists: []}} = useQuery<{specialists: Specialist[]}>(GET_SPECIALISTS)

    return (
        <Select {...DEFAULT_PROPS} loading={loading} {...customProps}>
            {
                specialists
                    .map(mapToOption)
            }
        </Select>
    )
}

const mapToOption = ({id, fio}: Specialist) => {
    return (<Select.Option value={id}>{fio}</Select.Option>)
}

export default SpecialistSelector;