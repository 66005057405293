import {gql} from "@apollo/client";

export const SAVE_RESTRICTION = gql(`mutation addRestriction($restriction: RestrictionInput) {
    addRestriction(restriction: $restriction) {
        id
    }
}`);

export const GET_RESTRICTION_TYPES = gql(`query {
    restrictionTypes { description name }
}`);

