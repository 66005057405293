import {
    deleteAccessToken, deleteExpiresIn, deleteRefreshToken,
    getAccessToken,
    getExpiresIn,
    getRefreshToken,
    setAccessToken,
    setExpiresIn
} from "./tokenUtils";
import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from "@apollo/react-hooks";
import {setContext} from "@apollo/client/link/context";
import {RetryLink} from "@apollo/client/link/retry";
import {TokenRefreshLink} from "apollo-link-token-refresh";
import jwt_decode from "jwt-decode";
import axios from "axios";

export const getApolloClient = (): ApolloClient<any> => new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([retryLink, authLink, jwtLink, httpLink]),
        queryDeduplication: false,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: "network-only"
            }
        }
    })

export const getNewToken = (): Promise<any> => {
    return axios.post("/rest/auth/refresh", {refreshToken: getRefreshToken()})
}

const httpLink = createHttpLink({
    uri: "/graphql"
})

const jwtLink = new TokenRefreshLink({
    isTokenValidOrUndefined: () =>  !isTokenExpired() || typeof getAccessToken() !== 'string',
    fetchAccessToken: getNewToken,
    handleFetch: (accessToken:any) => {
        const accessTokenDecrypted = jwt_decode<any>(accessToken);
        setAccessToken(accessToken);
        setExpiresIn(accessTokenDecrypted.exp);
    },
    handleResponse: (operation:any, accessTokenField:any) => (response:any) => {
        return { access_token: response.data.accessToken }
    },
    handleError: (err:any) => {
        deleteAccessToken()
        deleteRefreshToken()
        deleteExpiresIn()
        console.warn('Your refresh token is invalid. Try to relogin');
        console.error(err);
    }
})


const authLink = setContext((_: any, {headers}: any) => {
    return {
        headers: {
            ...headers,
            authorization: "Bearer " + getAccessToken(),
        },
    };
});

const retryLink = new RetryLink({
    delay: {
        initial: 3000,
        max: 10000,
        jitter: true
    },
    attempts: {
        max: 5,
        retryIf: (error:any, _operation:any) => !!error
    }
});

const isTokenExpired = (): boolean => {
    const tokenExpiresIn = getExpiresIn();
    const currentTime = new Date().getTime() / 1000;

    if (tokenExpiresIn < currentTime) {
        deleteExpiresIn();
        deleteRefreshToken();
        deleteAccessToken();
    }

    return (tokenExpiresIn - currentTime) <= 50;
};