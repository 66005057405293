import React, {useState} from "react";
import {Editor} from "./editor";
import {useMutation} from "@apollo/client";
import {SAVE_TIMESHEET_MESSAGE} from "./query.graphql";
import {Message} from "../../../model/message";
import {useTimesheetContext} from "../context";
import {useAuth} from "../../../auth/authContext";
import {notification} from "antd";

export const MessageInput = ({refetch }:{refetch: () => void }) => {
    const {timesheet} = useTimesheetContext()
    const {userInfo} = useAuth()
    const [message, setMessage] = useState("")
    const [sendMessage, {loading}] = useMutation<{props: Message}>(SAVE_TIMESHEET_MESSAGE)

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value)
    }

    const saveMessage = () => {
        sendMessage({
            variables: {
                props: {
                    message,
                    timesheet: { id: timesheet.id },
                    sender: {id: userInfo.id}
                }
            }
        }).then(() => {
            setMessage("")
            refetch()
            notification.success({message: "Сохранено"});
        })
    }

    return (<Editor onChange={onChange}
            onSubmit={saveMessage}
            submitting={loading}
            value={message} />
    )
}
