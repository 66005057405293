import React from "react";
import {Row, Col} from 'antd';
import {LoginForm} from "./form";

function LoginPage() {
    return (
        <Row style={{paddingTop: "10%", textAlign: "center"}} gutter={32}>
            <Col span={24}>
                <img src={"/logo.png"} alt="logo" width={"500"} />
                <Row gutter={32} style={{paddingTop: 50}}>
                    <Col span={6} offset={9} >
                        <LoginForm />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default LoginPage