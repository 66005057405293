import React from "react";
import {Modal} from "antd";
import ModifyBatchForm from "../../batch/edit/form";
import {ModalProps} from "antd/es";
import {useForm} from "antd/es/form/Form";
import {useTimesheetBatchesContext} from "./context";

interface AddBatchForTimesheetModalProps extends ModalProps {
    locationId: string | null
    onAfterFinish: () => void
}

const AddBatchForTimesheetModal = (props:AddBatchForTimesheetModalProps ) => {
    const {
        setCreateBatchModalProps, createBatchModalProps
    } = useTimesheetBatchesContext();
    const [form] = useForm()

    const closeModal = () => {
        form.resetFields();
        setCreateBatchModalProps({open: false})
    }

    return (
        <Modal {...props} open={createBatchModalProps.open}
               onOk={form.submit} okText={"Сохранить"}
               onCancel={closeModal} title={"Создание новой группы"}
        >
            <ModifyBatchForm mode={"SIMPLE"} onAfterFinish={props.onAfterFinish} form={form} locationId={props.locationId}/>
        </Modal>
    )
}

export default AddBatchForTimesheetModal;