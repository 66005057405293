import React, {useState} from "react";
import {Button, Col, Divider, Input, message, notification, Row, Spin, Table, TableColumnType} from "antd";
import {BATCH_LIST_COLUMNS} from "./cols";
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_BATCH, GET_BATCHES_FOR_LOCATION} from "../queries.graphql";
import Batch from "../../../model/batch";
import {Link, useLocation} from "react-router-dom";
import {isEmpty} from "../../../utils";
import DeleteButton from "../../../components/buttons/delete";

export interface GetBatchesResponseProps {
    batchesForLocation: Batch[]
}

interface BatchListPageProps {
    locationId: string | number | undefined
}

export const BatchListPage = ({locationId}: BatchListPageProps) => {
    const location = useLocation()
    const [filter, setFilter] = useState("");
    const {loading, data = {batchesForLocation: []}, refetch} = useQuery<GetBatchesResponseProps>(GET_BATCHES_FOR_LOCATION, {
        variables: { locationId: locationId }, skip: isEmpty(locationId)
    });
    const [deleteBatchMutation, {loading:deleting}] = useMutation(DELETE_BATCH)

    const deleteBatch = (id: string) => {
        deleteBatchMutation({
            variables: {
                batch: { id }
            }
        }).then(() => notification.success({message: "Сохранено"}))
            .then(() => refetch())
            .catch((reason) => {
                    notification.error({message: "При удалении возникли ошибки. Попробуйте позже"});
                    console.error(reason)
                }
            )
    }

    const actionsColumn:TableColumnType<Batch> = {
        title: "Действия",
        align: "center", width: 250,
        render: (value, {id, isPrimary}) => ([
                (<Link to={`/batches/edit/${id}`} state={{backgroundLocation: location}}>
                    <Button type={"link"}>Редактировать</Button>
                </Link>),
                <Divider type={"vertical"}/>,
                <DeleteButton type={"icon"} disabled={isPrimary || false} onConfirm={() => deleteBatch(id)}/>
            ])
    }

    const filteredData = (data.batchesForLocation || [])
        .filter(({name}) => name.toLowerCase().includes(filter.toLowerCase()))

    return (
        <Spin spinning={loading}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Table loading={loading}
                           columns={[...BATCH_LIST_COLUMNS, actionsColumn]} dataSource={filteredData}
                           footer={() => [
                               (<Link to={`/batches/new?locationId=${locationId}`} state={{backgroundLocation: location}}>
                                   <Button type={"link"}>Добавить</Button>
                               </Link>)
                           ]}
                    />
                </Col>
            </Row>
        </Spin>
    )
}