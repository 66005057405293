import React from "react";
import Title from "antd/es/typography/Title";
import {Avatar, Row, Space} from "antd";

import {UserOutlined} from '@ant-design/icons';
import Text from "antd/es/typography/Text";
import {Link} from "react-router-dom";
import {useAuth} from "../../auth/authContext";

const UserHeader = () => {
    const {userInfo} = useAuth()
    if (userInfo === undefined) return null;

    return (
        <Row align={"middle"} justify={"start"}>
            <Space>
                <Link to={"/"}>
                    <img className={"logo"} src={"/logo.png"} alt={"Логотип ЧОУП КРУГОЗОР"}/>
                </Link>
                <Avatar size={64} src={userInfo.iconUrl || "/img/profile_img.png"} icon={<UserOutlined/>}/>
                <Space direction={"vertical"} size={0} style={{lineHeight: "0px"}}>
                    <Title level={4}>Добро пожаловать, {userInfo.name} {userInfo.surName}!</Title>
                    <Text type={"secondary"}>{userInfo.description}</Text>
                    <Text type={"secondary"}>v {process.env.REACT_APP_VERSION}</Text>
                </Space>
            </Space>
        </Row>
    )
}

export default UserHeader;