import React from "react";
import {Select, Spin} from "antd";
import {SelectProps} from "antd/lib/select";
import {useQuery} from "@apollo/client";
import {GET_FAVORS_SELECTOR} from "./queries.graphql";
import Favor from "../../model/favor";


const DEFAULT_PROPS:SelectProps<any> = {
    placeholder: "Выберите услугу",
    showSearch: true,
    optionFilterProp: "children",
    style: {width: "100%"}
}

const FavorSelector = (customProps:SelectProps<any>) => {
    const {data = {favors: []}, loading, error} = useQuery<{favors: Favor[]}>(GET_FAVORS_SELECTOR);

    return (
        <Spin spinning={loading}>
            <Select {...DEFAULT_PROPS} {...customProps}>
                {
                    data.favors.map(favor => (
                        <Select.Option value={favor.id}>{favor.name}</Select.Option>
                    ))
                }
            </Select>
        </Spin>
    )
}

export default FavorSelector