import React from "react";
import {MaskedInput} from "antd-mask-input";


const PhoneNumberInput = (props:any) => {
    return (
        <MaskedInput {...props} mask={"+7 000 000 0000"} placeholder={"Введите номер телефона"}/>
    )
}

export default PhoneNumberInput;