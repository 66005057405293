import {gql} from "@apollo/client";

export const GET_TIMESHEETS = gql(`query timesheets($timesheet: TimesheetInput, $pageNumber: Int) {
  timesheets(timesheet: $timesheet, pageNumber: $pageNumber) {
    pageInfo {
        totalElements
        totalPages
        number
    }
    data {
        id
        date
        location { id name }
        defaultFavor { id name }
        specialist { id fio }
        status
        completedLessonsAmount
    }
  }
}`)

export const GET_TIMESHEETS_WIT_FILTERS = gql(`query timesheetsWithFilter($filters: TimesheetFilter, $pageNumber: Int, $pageSize: Int) {
  timesheetsWithFilter(filter: $filters, pageNumber: $pageNumber, pageSize: $pageSize) {
    pageInfo {
        totalElements
        totalPages
        number
    }
    data {
        id
        date
        location { id name }
        defaultFavor { id name }
        specialist { id fio }
        status
        completedLessonsAmount
    }
  }
}`)


export const GET_TIMESHEETS_FOR_SPECIALIST = gql(`query timesheets($specId: ID, $pageNumber: Int) {
  timesheets(specId: $specId, pageNumber: $pageNumber) {
    pageInfo {
        totalElements
        totalPages
    }
    data {
        id
        date
        location { id name }
        defaultFavor { id name }
        specialist { id }
        status
        completedLessonsAmount
    }
  }
}`)

export const EDIT_TIMESHEET_STATUS = gql(`mutation editTimesheetStatus($props: TimesheetInput) {
    editTimesheetStatus(props: $props) {
        id
        status
    }
}
`)