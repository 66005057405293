import moment, {Moment} from "moment";
import React from "react";
import MyColumnType from "../../components/model/myColumnType";
import Client from "../../model/client";
import Timesheet from "../../model/timesheet";
import {TimesheetStatus} from "../../model/timesheetStatus";
import Batch from "../../model/batch";

export const DELETE_LINE_ITEM_ID = "delete"
export const DATE_FORMAT = "YYYY-MM-DD";
export const isDateShouldBeDisabled = (date: Moment, datesColumns: Moment[], timesheetStrDate: string | Moment) => {
    const dates = datesColumns.map((colDate: Moment) => colDate.format("DD.MM.YYYY"));
    const timesheetDate = moment(timesheetStrDate);

    const isDateOnTimesheetMonth = timesheetDate.month() === date.month();

    return !isDateOnTimesheetMonth  || dates.includes(date.format("DD.MM.YYYY"));
}

export function onlyUniqueDates(value:Moment, index:any, self:Moment[]) {
    return self.findIndex((el:Moment) => el.isSame(value)) === index;
}
export function onlyUniqueClient(value:Client, index:any, self:Client[]) {
    return self.findIndex((el:Client) => el.id === value.id) === index;
}

export function onlyUniqueBatch(value:Batch, index:any, self:Batch[]) {
    return self.findIndex((el:Batch) => el.id === (value.id)) === index;
}

export const TIMESHEET_INITIAL:Timesheet = {
    clients: [],
    date: moment(),
    defaultFavor: undefined,
    lessons: [],
    id: null,
    status: TimesheetStatus.NEW,
    specialist: {id: null},
    notes: []
}

export const lessonsDatesColumnsComparator = (col1:MyColumnType, col2:MyColumnType) => moment(col1.raw.date).isAfter(moment(col2.raw.dateTime)) ? 1 : -1;